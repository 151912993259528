@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Ubuntu:wght@300;400;500;700&display=swap');
/*
font-family: 'Poppins', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Ubuntu', sans-serif;
*/
:root{
  --color01:#FBE7D3;
  --color02:#F14E2D;
  --color03:#FFF5E0;
  --color04:#FFA18E;
  --shades01:#ffffff;
  --shades02:#0D1225;
  --shades03:#F8FBFF;
  --shades04:#e4ecef;
  --shades05:#A8ABBD;
  --shades06:#6E757B;
  --shades07:#4B546E;
  --shades08:#282C3F;
  --colorSuccess:#22C55E;
  --colorDanger:#F43F5E;
  --colorInfo:#0EA5E9;
  --colorWaning:#F59E0B;
  --height:100px;
}

body, html{ width: 100%; line-height:1.4; margin:0 auto !important;padding:0 !important;font-family: 'Poppins', sans-serif; font-weight: 400; -webkit-font-smoothing: subpixel-antialiased;text-shadow: 1px 1px 1px rgba(0,0,0,0.004);font-size: 14px;  color: var(--shades02); background:var(--shades03);position: relative; z-index: 0;}
*:focus{outline: none !important;outline-offset: none !important;outline-offset: 0 !important;}
a{text-decoration:none; color: var(--shades08);}
a:hover{text-decoration: none;-webkit-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -o-transition: all 0.3s ease; -ms-transition: all 0.3s ease;transition: all 0.3s ease; color: var(--shades02);}
a:focus{ outline: none;text-decoration: none;}
button{cursor: pointer; outline: none; border: 0;-webkit-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -o-transition: all 0.3s ease; -ms-transition: all 0.3s ease;transition: all 0.3s ease;}
input:focus, label:focus{outline: none !important;outline-offset: none !important;outline-offset: 0 !important; box-shadow: none !important;}

/* .dropdown-menu{display: block; opacity: 0; visibility: hidden; transition:opacity 0.3s ease, visibility 0.3s ease;}
.dropdown-menu.show{opacity: 1; visibility: visible;}
.dropdown-menu-end{right:0} */

/*######### UTILITIES #########*/
.fa-rotate-n30{-webkit-transform: rotate(-30deg);transform: rotate(-30deg);}
.divide-x > div+div{border-left:1px solid var(--shades04);}

.bg-color-01{background-color:var(--color01)}
.bg-color-02{background-color:var(--color02)}
.bg-color-03{background-color:var(--color03)}
.bg-color-04{background-color:var(--color04)}
.bg-shades-01{background-color:var(--shades01)}
.bg-shades-02{background-color:var(--shades02)}
.bg-shades-03{background-color:var(--shades03)}
.bg-shades-04{background-color:var(--shades04)}
.bg-shades-05{background-color:var(--shades05)}
.bg-shades-06{background-color:var(--shades06)}
.bg-shades-07{background-color:var(--shades07)}
.bg-shades-08{background-color:var(--shades08)}
.bg-success{background-color:var(--colorSuccess) !important;}
.bg-danger{background-color:var(--colorDanger) !important;}
.bg-info{background-color:var(--colorInfo) !important;}
.bg-warning{background-color:var(--colorWaning) !important;}

.text-color-01{color:var(--color01) !important;}
.text-color-02{color:var(--color02) !important;}
.text-color-03{color:var(--color03) !important;}
.text-color-04{color:var(--color04) !important;}
.text-shades-01{color:var(--shades01) !important;}
.text-shades-02{color:var(--shades02) !important;}
.text-shades-03{color:var(--shades03) !important;}
.text-shades-04{color:var(--shades04) !important;}
.text-shades-05{color:var(--shades05) !important;}
.text-shades-06{color:var(--shades06) !important;}
.text-shades-07{color:var(--shades07) !important;}
.text-shades-08{color:var(--shades08) !important;}

.text-xs{font-size:10px !important;}
.text-sm{font-size:12px !important;}
.text-md{font-size:14px !important;}
.text-lg{font-size:18px !important;}
.text-xl{font-size:20px !important;}

/*######### UTILITIES END #########*/



/*######### COMPONENTS #########*/

/*----- Global - Scrollbar -----*/
.scrollbar::-webkit-scrollbar{width: 6px;height: 6px;transition:all 0.3s ease;}
.scrollbar::-webkit-scrollbar-track{border-radius: 100vh;background: transparent;transition:all 0.3s ease;}
.scrollbar::-webkit-scrollbar-thumb{background:var(--color02);border-radius: 100vh;transition:all 0.3s ease;}
.scrollbar-alt::-webkit-scrollbar-thumb{background:var(--shades08);}

/*----- Global - Action Dropdown -----*/
.action-dropdown{position: relative;}
.action-dropdown .dropdown-toggle{width: 20px;height: 30px;display: flex;justify-content: center;align-items: center;padding: 0;background-color: var(--shades01);border-radius: 4px;font-size: 16px;color: var(--shades05);}
.action-dropdown .dropdown-toggle:after{display: none;}
.action-dropdown .dropdown-menu{min-width: 160px;border: 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 5px;border-radius: 8px; margin: 4px 0 0 !important;}
.action-dropdown .dropdown-item{display: flex;align-items: center;padding: 6px 12px; color: var(--shades07); gap: 10px; width: 100%; background-color: transparent;font-size: 13px;}
.action-dropdown .dropdown-item i{font-size: 16px;}
.action-dropdown .dropdown-item:hover{background-color: var(--shades04);}
.action-dropdown .dropdown-item+.dropdown-item{border-top:1px solid var(--shades04)}
.action-dropdown .dropdown-item.standOut{color: var(--colorDanger);}
.action-dropdown .dropdown-item.standOut:hover{background-color: var(--colorDanger); color: var(--shades01);}

/*----- Dropdown - Dropdown Checkbox -----*/
.dropdown-select{}
.dropdown-select .checkbox{align-items: center;}
.dropdown-select .checkbox .checkbox-image{width: 30px;width: 30px;overflow: hidden;border-radius: 50%;}
.dropdown-select .reason-dropdown{width: 100%;height: 40px;background-color: var(--shades01);font-size: 13px;color: var(--shades07);border: 1px solid var(--shades04);border-radius: 4px;padding: 0 10px;text-align: left;}
.dropdown-select .dropdown-toggle::after{content: '\f107';font-family: "Font Awesome 6 Pro";border-top: 0;position: absolute;right:8px;font-size: 16px;top: 8px;margin-left: 0;}
.dropdown-select .dropdown-menu{width: 100%;padding: 15px;}
.dropdown-select .dropdown-menu .dropdown-item{}
.dropdown-select .dropdown-menu .dropdown-item.active{background: var(--color02) !important;color: var(--shades01) !important;}
.dropdown-select .dropdown-menu .dropdown-checkbox{}
.dropdown-select .dropdown-menu .dropdown-checkbox+ .dropdown-checkbox{margin-top: 10px;}
.dropdown-select .dropdown-search{border-bottom: 1px solid var(--shades04);margin-bottom: 10px;}
.dropdown-select .input{}
.dropdown-select .input .input-style{}
.dropdown-select .input .input-field{border: none;width: 100%;max-width: 100%;min-width: 100%;padding: 0;}

/*----- Dropdowns - Notifications -----*/
.notification{position: relative;}
.notification .dropdown-toggle{padding: 0;background-color: transparent;color: var(--shades05);width: 40px;height: 40px;position: relative;display: flex;justify-content: center;align-items: center;font-size: 20px;}
.notification .dropdown-toggle:after{display: none;}
.notification .dropdown-toggle .count{position: absolute;display: flex;align-items: center;justify-content: center;font-size: 10px;background-color: var(--colorDanger);color: var(--shades01);width: 20px;height: 20px;border-radius: 50%;top: 0;right: 0;z-index: 1;}
.notification .dropdown-menu{min-width: 360px;border: 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 5px;border-radius: 8px;padding: 0;}
.notification .dropdown-header{font-size: 14px;color: var(--shades02);font-weight: 500;padding: 10px 16px; border-bottom: 1px solid var(--shades04);}
.notification .dropdown-body{padding: 10px 0; max-height: 300px; overflow: auto;}
.notification .dropdown-body > div+div{border-top:1px solid var(--shades04);}
.notification .dropdown-footer{padding: 10px;border-top: 1px solid var(--shades04); display: flex; justify-content: center;}
.notification .dropdown-footer .link{font-size: 13px;font-weight: 500;color: var(--shades06);}
.notification .notification-item{padding: 10px;display: flex; gap: 20px; transition: all 0.3s ease;}
.notification .notification-item:hover{background-color: var(--shades03);}
.notification .notification-item+.notification-item{border-top:1px solid var(--shades04);}
.notification .notification-item .notification-image{width: 50px; min-width: 50px; height: 50px;overflow: hidden;border-radius: 50%;}
.notification .notification-item .notification-content{width: 100%;}
.notification .notification-item .notification-title{font-size: 13px;font-weight: 600;}
.notification .notification-item .notification-data{font-size: 12px;color: var(--shades06);font-weight: 400;}
.notification .notification-item .notification-time{font-size: 11px;font-weight: 400;color: var(--shades05);}

/*----- Dropdowns - User Menu -----*/
.usermenu{position: relative;}
.usermenu .dropdown-toggle{display: flex;align-items: center;gap: 10px;background-color: transparent;padding: 0;}
.usermenu .dropdown-toggle:after{display: none;}
.usermenu .dropdown-toggle .usermenu-image{width: 48px;height: 48px;position: relative; border-radius: 50%; overflow: hidden;}
.usermenu .dropdown-toggle .usermenu-title{font-size: 13px;color: var(--shades01);font-weight: 300;}
.usermenu .dropdown-menu{min-width: 160px;border: 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 5px;border-radius: 8px; margin: 10px 0 0 !important;}
.usermenu .dropdown-menu > div+div{border-top:1px solid var(--shades04)}
.usermenu .usermenu-item{display: flex;align-items: center;padding: 6px 12px; color: var(--shades07); gap: 10px; width: 100%; background-color: transparent;}
.usermenu .usermenu-item:hover{background-color: var(--shades04);}
.usermenu .usermenu-item+.usermenu-item{border-top:1px solid var(--shades04)}
.usermenu .usermenu-item .icon{font-size: 16px;}
.usermenu .usermenu-item .title{font-size: 13px;}
.usermenu .usermenu-item.standOut{color: var(--colorDanger);}
.usermenu .usermenu-item.standOut:hover{background-color: var(--colorDanger); color: var(--shades01);}

/*----- Elements - Confirm -----*/
.confirm-box{position: fixed;top: 0;left: 0;width: 100%;height: 100%; z-index: 99;overflow-y: auto;overflow-x: hidden; padding: 0; opacity: 0; visibility: hidden;transition: all 0.3s ease;}
.confirm-box .confirm-box-backdrop{position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: var(--shades02);opacity: 0.5;z-index: -1;opacity: 0; visibility: hidden; transition: all 0.3s ease;}
.confirm-box .confirm-box-inner{position: relative;width: 100%; height: 100%; display: flex; align-items: center; margin: auto; padding: 20px;}
.confirm-box .confirm-box-content{ position: relative; width: 100%;max-width: 360px; background-color: var(--shades03);border-radius: 10px;box-shadow: rgb(0 0 0 / 40%) 0 2px 5px;padding: 20px;margin: auto; opacity: 0; visibility: hidden; transition: all 0.3s ease;}
.confirm-box .confirm-box-icon{font-size: 60px;color: var(--shades01);background-color: var(--shades05);padding: 0;line-height: 1;width: 80px;height: 80px;border-radius: 50%;display: flex;justify-content: center;align-items: center;margin: -60px auto 20px;}
.confirm-box .confirm-box-title{font-size: 20px;font-weight: 500;text-align: center;margin: 0 0 20px;}
.confirm-box .confirm-box-text{text-align: center;font-size: 14px;font-weight: 300;color: var(--shades06);margin: 0 0 20px;}
.confirm-box .confirm-box-actions{ display: flex; column-gap: 20px; row-gap: 10px; margin: 30px 0 0;padding: 10px 0 0; border-top: 1px solid var(--shades04);}
.confirm-box.active{opacity: 1; visibility: visible;}
.confirm-box.active .confirm-box-backdrop{opacity: 0.5; visibility: visible;}
.confirm-box.active .confirm-box-content{opacity: 1; visibility: visible;}


/*----- Elements - Modal -----*/
.dialog-box{position: fixed;top: 0;left: 0;width: 100%;height: 100%; z-index: 99;overflow-y: auto;overflow-x: hidden; padding: 0; opacity: 0; visibility: hidden;transition: all 0.3s ease;}
.dialog-box .dialog-box-backdrop{position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: var(--shades02);opacity: 0.5;z-index: -1;opacity: 0; visibility: hidden; transition: all 0.3s ease;}
.dialog-box .dialog-box-inner{position: relative;width: 100%; height: 100%; display: flex; align-items: center; margin: auto; padding: 20px;}
.dialog-box .dialog-box-content{ position: relative; width: 100%;max-width: 1200px; background-color: var(--shades03);border-radius: 10px;box-shadow: rgb(0 0 0 / 40%) 0 2px 5px;padding: 20px;margin: auto; opacity: 0; visibility: hidden; transition: all 0.3s ease;}
.dialog-box .dialog-box-content.lg{max-width: 1024px;}
.dialog-box .dialog-box-content.md{max-width: 800px;}
.dialog-box .dialog-box-content.sm{max-width: 560px;}
.dialog-box .dialog-box-content.xs{max-width: 420px;}
.dialog-box .dialog-box-close{width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;border-radius: 50%;font-size: 20px;line-height: 1;padding: 2px 0 0;color: var(--shades06);background-color: var(--shades01);box-shadow: rgb(0 0 0 / 25%) 0 0 5px;top: -20px;right: -20px;position: absolute;z-index: 1;}
.dialog-box .dialog-box-close:hover{color: var(--colorDanger); transform: rotate(90deg);}
.dialog-box .dialog-box-header{display: flex;align-items: center;padding: 0 0 10px;margin: 0 0 10px;border-bottom: 1px solid var(--shades04);}
.dialog-box .dialog-box-title{font-size: 20px;font-weight: 500;line-height: 1;}
.dialog-box .dialog-box-body{position: relative; padding: 20px 0;/*max-height: calc(100vh - 140px);overflow-y: auto;overflow-x: hidden;*/}
.dialog-box.active{opacity: 1; visibility: visible;}
.dialog-box.active .dialog-box-backdrop{opacity: 0.5; visibility: visible;}
.dialog-box.active .dialog-box-content{opacity: 1; visibility: visible;}

/*----- Elements - Pagination -----*/
.pagination-section{display: flex;align-items: center;justify-content: flex-end;}
.pagination-section .button-pagination{background: var(--color02);color: var(--shades01);padding: 5px 10px;border-radius: 5px;}
.pagination-section .button-pagination[disabled]{opacity: 0.3;}
.pagination-section .page-number{background: var(--shades01);padding: 0 10px;font-size: 16px;font-weight: 500;}

/*----- Elements - Sidebar Toggle -----*/
.details-box{position: fixed;top: 0;left: 0;width: 100%;height: 100%; z-index: 99;overflow-y: auto;overflow-x: hidden; padding: 0; opacity: 0; visibility: hidden;transition: all 0.3s ease;}
.details-box .details-box-backdrop{position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: var(--shades02);opacity: 0.5;z-index: -1;opacity: 0; visibility: hidden; transition: all 0.3s ease;}
.details-box .details-box-content{ position: relative; width: 100%;max-width: 600px; background-color: var(--shades03);border-radius: 0px;box-shadow: rgb(0 0 0 / 40%) 0 2px 5px;padding: 20px;margin-left: auto; opacity: 0; visibility: hidden; transition: all 0.3s ease;overflow: auto;height: 100%;}
.details-box .details-box-close{width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;border-radius: 50%;font-size: 20px;line-height: 1;padding: 2px 0 0;color: var(--shades06);background-color: var(--shades01);box-shadow: rgb(0 0 0 / 25%) 0 0 5px;position: relative;z-index: 1;}
.details-box .details-box-close:hover{color: var(--colorDanger); transform: rotate(90deg);}
.details-box .details-box-header{display: flex;align-items: center;padding: 0 0 10px;margin: 0 0 10px;border-bottom: 1px solid var(--shades04);justify-content: flex-end;}
.details-box .details-box-title{font-size: 20px;font-weight: 500;line-height: 1;}
.details-box .details-box-body{position: relative; padding: 20px 0;/*max-height: calc(100vh - 140px);overflow-y: auto;overflow-x: hidden;*/}
.details-box.active{opacity: 1; visibility: visible;}
.details-box.active .details-box-backdrop{opacity: 0.5; visibility: visible;}
.details-box.active .details-box-content{opacity: 1; visibility: visible;}
.order-details-content {}
.order-details-content .customer-details{}
.order-details-content .customer-details .name{font-size: 24px;margin-bottom: 10px;font-weight: 600;display: flex;align-items: center;}
.order-details-content .customer-details .name .total-order{font-size: 12px;background: var(--shades04);padding: 4px 10px;border-radius: 5px;font-weight: 600;margin-left: 5px;}
.order-details-content .customer-details .data-item{font-size: 14px;font-weight: 600;}
.order-details-content .customer-details .data-item span{font-weight: 400;color: var(--shades06);margin-right: 8px;}
.order-details-content .item-title{font-size: 16px;text-decoration: underline;font-weight: 600;margin-bottom: 15px;}
.order-details-content .order-step-progress{}
.order-details-content .order-step-progress .order-step{display: flex;position: relative;padding-bottom: 40px;}
.order-details-content .order-step-progress .order-step:before{content: '';position: absolute;width: 2px;height: 100%;background: var(--shades04);top: 0;left: 24px;}
.order-details-content .order-step-progress .order-step:last-child{padding-bottom: 0;}
.order-details-content .order-step-progress .order-step .icon{background: var(--shades04);width: 50px;height: 50px;display: flex;align-items: center;justify-content: center;font-size: 18px;position: relative;border-radius: 50px;}
.order-details-content .order-step-progress .order-step .order-content{width: 100%;max-width: calc(100% - 70px);margin-left: 20px;}
.order-details-content .order-step-progress .order-step .order-content .title{font-size: 14px;font-weight: 500;color: var(--shades07);}
.order-details-content .order-step-progress .order-step .order-content .date{font-size: 12px;color: var(--shades05);}
.order-details-content .order-step-progress .order-step.active{}
.order-details-content .order-step-progress .order-step.active .icon{background: var(--colorSuccess);color: var(--shades01);}
.order-details-content .order-step-progress .order-step.active:before{background: var(--colorSuccess);}
.order-details-content .order-step-progress .order-step.active .order-content .title{color: var(--colorSuccess);}
.order-details-content .order-list{height: 350px;overflow: auto;padding-right: 5px;}
.order-details-content .order-list::-webkit-scrollbar{width: 3px;height: 6px;transition:all 0.3s ease;margin: 0 2px;}
.order-details-content .order-list::-webkit-scrollbar-track{border-radius: 100vh;background: transparent;transition:all 0.3s ease;}
.order-details-content .order-list::-webkit-scrollbar-thumb{background:var(--shades06);border-radius: 100vh;transition:all 0.3s ease;}
.order-details-content .order-list .order-item{display: flex;justify-content: space-between;padding: 10px 0;}
.order-details-content .order-list .order-item+ .order-item{border-top: 1px solid var(--shades04);}
.order-details-content .order-list .order-item .item-details{}
.order-details-content .order-list .order-item .item-details .sub-text{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.order-details-content .order-list .order-item .item-details .order{font-size: 14px;font-weight: 500;display: flex;flex-wrap: wrap;}
.order-details-content .order-list .order-item .item-details .other-data{display: flex;align-items: center;font-size: 14px;font-weight: 500;}
.order-details-content .order-list .order-item .item-details .other-data span{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);margin-right: 5px;}
.order-details-content .order-list .order-item .item-price{}
.order-details-content .order-list .order-item .item-price .other-data{display: flex;justify-content: flex-end;align-items: center;font-size: 14px;font-weight: 500;}
.order-details-content .order-list .order-item .item-price .other-data span{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);margin-right: 5px;}
.order-details-content .order-price-history{}
.order-details-content .order-price-history .price-info{}
.order-details-content .order-price-history .price-info .price-row{display: flex;justify-content: flex-end;}
.order-details-content .order-price-history .price-info .price-row+ .price-row{margin-top: 5px;}
.order-details-content .order-price-history .price-info .price-row .label{width: 150px;}
.order-details-content .order-price-history .price-info .price-row .data{width: 100%;max-width: calc(100% - 160px);text-align: right;margin-left: 10px;font-size: 14px;font-weight: 500;}
.order-details-content .order-price-history .price-info .price-row .data.color-danger{color: var(--colorDanger);}
.order-details-content .order-price-history .price-info .price-row .data.color-success{color: var(--colorSuccess);}
.order-details-content .order-price-history .price-total{margin: 8px 0 0;border-top: 1px solid var(--shades04);padding: 5px 0 0;}
.order-details-content .order-price-history .price-total .price-row{display: flex;justify-content: flex-end;}
.order-details-content .order-price-history .price-total .price-row .label{    width: 150px;font-size: 16px;color: var(--shades07);font-weight: 400;}
.order-details-content .order-price-history .price-total .price-row .data{width: 100%;max-width: calc(100% - 160px);text-align: right;margin-left: 10px;font-weight: 500;font-size: 16px;}
.order-details-content .shipping-details{background: var(--shades01);padding: 20px;border-radius: 10px;box-shadow: 0px 0px 6px rgb(128 128 128 / 30%);}
.order-details-content .shipping-details .shipping-note{margin-bottom: 10px;border-bottom: 1px solid var(--bs-gray-300);padding-bottom: 10px;}
.order-details-content .shipping-details .sub-text{font-size: 14px;text-transform: capitalize;color: var(--shades07);font-weight: 500;}
.order-details-content .shipping-details .shipping-note{}
.order-details-content .shipping-details .shipping-note .note{font-size: 16px;font-weight: 500;}
.order-details-content .shipping-details .shipping-other-details{}
.order-details-content .shipping-details .shipping-other-details .other-data{margin-top: 10px;}
.order-details-content .shipping-details .shipping-other-details .name{font-size: 16px;font-weight: 500;margin-bottom: 5px;}
.order-details-content .shipping-details .shipping-other-details .data-row{}
.order-details-content .shipping-details .shipping-other-details .data-item{display: flex;flex-direction: column;font-size: 14px;font-weight: 500;display: flex;flex-wrap: wrap;}
.order-details-content .shipping-details .shipping-other-details .data-item+ .data-item{margin-top: 4px;}
.order-details-content .shipping-details .shipping-other-details .data-item span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}

/*----- Elements - Preview Product -----*/
.product-preview{position: relative;}
.product-preview .preview-label{font-size: 13px;line-height: 18px;font-weight: 400;color: var(--shades07);}
.product-preview .preview{position: relative; padding: 40px;}
.product-preview .preview-inner{position: relative;background-color: var(--color01);border-radius: 50px;padding: 30px;padding-bottom: 60px;width: 100%;max-width: 360px;margin: 0 auto;}
.product-preview .preview .title{font-size: 24px;font-weight: 600;}
.product-preview .preview .image{position: relative;transform: translateX(-80px);max-height: 240px;}
.product-preview .preview .actions{position: absolute;bottom: 0;right: 0;background-color: var(--color02);border-radius: 50px 0 50px;display: flex;justify-content: center;align-items: center;flex-direction: column;padding:20px;color: var(--shades01);font-size: 20px;}
.product-preview .preview .actions .price{font-size: 24px;font-weight: 600;color: var(--shades01);line-height: 1.5;}
.product-preview .preview .actions .action-label{font-size: 12px;text-transform: uppercase;font-weight: 500;color: var(--shades01); line-height: 1;}
.product-preview .preview .ingredients{position: absolute;top: 40px;right: -16px;}
.product-preview .preview .ingredients .item{width: 32px;height: 32px;background-color: var(--color02);border-radius: 50%;padding: 3px 5px 7px;border: 3px solid var(--shades01);display: flex;align-items: center;justify-content: center; box-shadow: rgba(0,0,0,0.1) 0 2px 4px;}
.product-preview .preview .ingredients .item+.item{margin: 2px 0 0;}
.product-preview .preview .ingredients .item img{filter: brightness(0) invert(1);}


/*----- Forms - Button -----*/
.main-button{display: flex; justify-content: center; align-items: center; height: 40px; padding: 0 20px; border-radius: 4px; background-color: var(--color02); color: var(--shades01); gap: 10px; font-size: 18px; line-height: 1; border:1px solid var(--color02);}
.main-button .button-label{font-size: 14px;}
.main-button.main-button-alt{background-color: var(--shades01); color: var(--shades07); border-color: var(--shades04);}
.main-button.main-button-link{background-color:transparent; color: var(--shades07); border-color: transparent; padding: 0; text-decoration: underline;}
.main-button.main-button-icon-sm{background-color:transparent; color: var(--shades07); border-color: transparent; padding: 0 4px; height: 24px; font-size: 14px;}


/*----- Forms - Checkbox -----*/
.checkbox{position: relative;line-height: 1;gap: 10px; display: flex;border-radius: 3px;}
.checkbox .checkbox-input{position: absolute;top: 0;left: 0;width: 20px;height: 20px;opacity: 0;z-index: 1;cursor: pointer;}
.checkbox .checkbox-icon{position: relative;overflow: hidden;width: 20px;min-width:20px;height: 20px;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 3px;transition: all 0.3s ease;}
.checkbox .checkbox-icon:before{content: '\f00c'; font-family: 'Font Awesome 6 Pro';width: 18px;height: 18px;font-size: 12px;display: flex;align-items: center;justify-content: center;position: absolute;top: 0;left: 0;color: var(--shades01); opacity: 0;}
.checkbox .checkbox-label{font-size: 13px;line-height: 20px;color: var(--shades07);}
.checkbox .checkbox-label.required:after{content:'*'; margin-left: 2px;}
.checkbox .checkbox-input:checked ~ .checkbox-icon{border-color: var(--color02); background-color: var(--color02);}
.checkbox .checkbox-input:checked ~ .checkbox-icon:before{opacity: 1;}

/*----- Forms - Image Upload Preview -----*/
.image-upload-preview{position: relative; margin:0 0 20px;}
.image-upload-preview .input-label{font-size: 13px; line-height: 18px; font-weight: 400; color: var(--shades07);}
.image-upload-preview .input-label.required:after{content:'*'; margin-left: 2px;}
.image-upload-preview .image-preview{display: flex;flex-wrap: wrap;margin: 10px -8px 0;}
.image-upload-preview .image-preview .item{width: 100%; min-width: 100px; max-width: calc(25% - 16px);margin: 0 8px 16px; position: relative;}
.image-upload-preview .image-preview .item-inner{width: 100%;height: 100px;overflow: hidden;border: 1px solid var(--shades04);border-radius: 6px;background-color: var(--shades01);}
.image-upload-preview .image-preview .item-inner img{width: 100%;height: 100px; object-fit:cover ;}
.image-upload-preview .image-preview .item-remove{position: absolute;top: -10px;right: -10px;z-index: 1;width: 20px;height: 20px;padding: 0;display: flex;align-items: center;justify-content: center;font-size: 14px;color: var(--colorDanger);background-color: var(--shades01);border-radius: 50%;box-shadow: rgb(0 0 0 / 10%) 0 2px 5px;}
.image-upload-preview .image-preview .add-item{position: relative;}
.image-upload-preview .image-preview .add-item .item-inner{display: flex;align-items: center;justify-content: center;flex-direction: column;font-size: 20px;color: var(--shades05); gap: 4px;position: relative;}
.image-upload-preview .image-preview .add-item .item-inner span{ font-size: 13px;}
.image-upload-preview .image-preview .add-item .item-input{position: absolute; top: 0; right: 0; width: 300px; height: 100%; z-index: 1; opacity: 0; cursor: pointer;}


/*----- Forms - Input -----*/
.input{position: relative;}
.input .input-options{display: flex;align-items: center; gap: 6px; margin: 0 0 2px;}
.input .checkbox .checkbox-input{width: 16px;height: 16px;}
.input .checkbox .checkbox-icon{width: 16px;height: 16px;}
.input .checkbox .checkbox-icon:before{width: 14px;height: 14px;}
.input .checkbox .checkbox-label{line-height: 16px;}
.input .input-label{font-size: 13px; line-height: 18px; font-weight: 400; color: var(--shades07);}
.input .input-label.required:after{content:'*'; margin-left: 2px;}
.input .label-button{height: 18px;padding: 0 6px;}
.input .label-button .button-label{font-size: 10px; font-weight: 300;}
.input .input-style{position: relative; display: flex;}
.input .input-field{width: 100%; height: 40px; background-color: var(--shades01); font-size: 13px; color: var(--shades07); border:1px solid var(--shades04); border-radius: 4px; padding: 0 10px;}
.input .input-button{width: 40px; height: 40px; background-color: transparent; color: var(--shades05); position: absolute; top: 0; right: 0; border-radius: 4px;}
.input .input-note{display: flex; font-size: 11px; margin: 4px 0 0; color: var(--shades07); gap: 4px;}
.input .input-note i{font-size: 13px;}
.input .input-note.text-success{color: var(--colorSuccess);}
.input .input-note.text-danger{color: var(--colorDanger);}
.input .input-note.text-warning{color: var(--colorWaning);}
.input .input-note.text-info{color: var(--colorInfo);}


/*----- Forms - Radio -----*/
.radio{position: relative;line-height: 1;gap: 10px; display: flex;}
.radio .radio-input{position: absolute;top: 0;left: 0;width: 20px;height: 20px;opacity: 0;z-index: 1;cursor: pointer;border-radius: 50%;}
.radio .radio-icon{position: relative;overflow: hidden;width: 20px;height: 20px;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 50%;transition: all 0.3s ease;}
.radio .radio-icon:before{content: '\f00c'; font-family: 'Font Awesome 6 Pro';width: 18px;height: 18px;font-size: 12px;display: flex;align-items: center;justify-content: center;position: absolute;top: 1px;left: 0;color: var(--shades01); opacity: 0;}
.radio .radio-label{font-size: 13px;line-height: 20px;color: var(--shades07);}
.radio .radio-label.required:after{content:'*'; margin-left: 2px;}
.radio .radio-input:checked ~ .radio-icon{border-color: var(--color02); background-color: var(--color02);}
.radio .radio-input:checked ~ .radio-icon:before{opacity: 1;}
.radio .radio-icon.active{border-color: var(--color02); background-color: var(--color02);}
.radio .radio-icon.active:before{opacity: 1;}


/*----- Forms - Radio Group -----*/
.radio-group{display: flex;}
.radio-group .radio-item{position: relative; overflow: hidden;z-index: 0;}
.radio-group .radio-item .radio-input{position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1; opacity: 0; cursor: pointer;}
.radio-group .radio-item .radio-label{font-size: 12px;height: 28px;background-color: var(--shades01);display: flex;justify-content: center;align-items: center;padding: 0 12px;line-height: 1;font-weight: 400;border: 1px solid var(--shades04); transition: all 0.3s ease;}
.radio-group .radio-item+.radio-item{margin-left: -1px;}
.radio-group .radio-item:first-child .radio-label{border-radius: 6px 0 0 6px;}
.radio-group .radio-item:last-child .radio-label{border-radius: 0 6px 6px 0;}
.radio-group .radio-item .radio-input:checked ~ .radio-label{background-color: var(--shades05); color: var(--shades01);}
.radio-group .radio-item .radio-input:checked ~ .radio-label.success{background-color: var(--colorSuccess);border-color: var(--colorSuccess);}
.radio-group .radio-item .radio-input:checked ~ .radio-label.danger{background-color: var(--colorDanger);border-color: var(--colorDanger);}
.radio-group .radio-item .radio-input:checked ~ .radio-label.warning{background-color: var(--colorWaning);border-color: var(--colorWaning);}
.radio-group .radio-item .radio-input:checked ~ .radio-label.info{background-color: var(--colorInfo);border-color: var(--colorInfo);}


/*----- Forms - RadiosLabel -----*/
.input-radio-group{display: flex;flex-wrap: wrap;gap: 24px;padding: 4px 0;}


/*----- Forms - Textarea -----*/
.input textarea.input-field{min-height: 120px; padding: 10px;}


/*----- Forms - Search -----*/
.search{display: flex;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 4px;position: relative;}
.search .icon{width: 40px;min-width: 40px;height: 40px;display: flex;align-items: center;justify-content: center;font-size: 16px;color: var(--shades05);}
.search .search-input{width: 100%;border: 0;font-size: 13px;color: var(--shades07);padding: 0 10px 0 0;border-radius: 0 4px 4px 0;}


/*----- Forms - Select Tag -----*/
.select-tag [class$="-placeholder"], .select-tag [class$="-ValueContainer"] input{color: var(--shades07) !important;font-size: 13px !important;}
.select-tag [class$="-control"]{min-height: 40px; border-color: var(--shades04) !important; box-shadow: none !important; outline: none !important;line-height: 1;}
.select-tag [class$="-indicatorSeparator"]{display: none;}
.select-tag [class$="-indicatorContainer"]:first-child{display: none;}
.select-tag [class$="-multiValue"]{ background-color: var(--color02); border-radius: 3px;}
.select-tag [class$="-multiValue"] [class$="-MultiValueGeneric"]{ color: var(--shades01); font-size: 11px; padding: 6px;}
.select-tag [class$="-multiValue"] [class$="-MultiValueRemove"]{ background-color: transparent; color: var(--shades01) !important;}
.select-tag [class$="-menu"]{border: 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 5px; }
.select-tag [class$="-MenuList"]{max-height: 200px; overflow: auto;}
.select-tag [class$="-MenuList"]::-webkit-scrollbar{width: 6px;height: 6px;transition:all 0.3s ease;}
.select-tag [class$="-MenuList"]::-webkit-scrollbar-track{border-radius: 100vh;background: transparent;transition:all 0.3s ease;}
.select-tag [class$="-MenuList"]::-webkit-scrollbar-thumb{background:var(--color02);border-radius: 100vh;transition:all 0.3s ease;}
.select-tag [class$="-MenuList"] > div{font-size: 12px; color: var(--shades08); background-color: var(--shades01);}
.select-tag [class$="-MenuList"] > div:hover{background-color: var(--shades04);}


/*----- Forms - Switch -----*/
.switch{width: 60px;height: 30px;background-color: var(--shades07);border-radius: 30px; padding: 4px; display: flex; align-items: center; justify-content: flex-start; position: relative; cursor: pointer;}
.switch .handle{width: 22px;height: 22px;background-color: var(--shades05);border-radius: 50%;}
.switch[dataison="true"]{justify-content: flex-end;background-color: var(--colorSuccess);}
.switch[dataison="true"] .handle{background-color: var(--shades01); box-shadow: rgba(0,0,0,0.5) 0 0 3px;}


/*----- Modals - Addon Group -----*/
.modification{position: relative; margin:0 0 20px;}
.modification .modification-list{position: relative; }
.modification .modification-list .item{display: flex;background-color: var(--shades01);border: 1px solid var(--shades04);align-items: center;padding: 8px 0;justify-content: space-between;line-height: 1;}
.modification .modification-list .item:first-child{border-radius: 6px 6px 0 0;}
.modification .modification-list .item:last-child{border-radius: 0 0 6px 6px;}
.modification .modification-list .item+.item{margin: -1px 0 0;}
.modification .modification-list .item .item-title{font-size: 13px;padding: 0 16px;font-weight: 500;color: var(--shades07);}
.modification .modification-list .item .item-action{display: flex;justify-content: flex-end;}


/*----- Navigation - Side Navigation -----*/
.navigation-menu{padding-left: 0;list-style: none;margin: 0;}
.navigation-menu > li{display: flex;align-items: center;justify-content: center;}
.navigation-menu > li+li{margin: 20px 0 0;}
.navigation-menu .navigation-items{display: flex;flex-direction: column;justify-content: center;align-items: center; gap: 4px;}
.navigation-menu .navigation-items .icon{width: 50px;height: 50px;background-color: var(--shades02);color: var(--shades07);display: flex;justify-content: center;align-items: center;font-size: 24px;border: 1px solid var(--shades07);border-radius: 50%; transition: all 0.3s ease;}
.navigation-menu .navigation-items .title{font-size: 13px;font-weight: 400;color: var(--shades07);transition: all 0.3s ease;}
.navigation-menu .navigation-items.active .icon,
.navigation-menu .navigation-items:hover .icon{background-color: var(--color02); border-color: var(--color02); color: var(--shades01);}
.navigation-menu .navigation-items.active .title,
.navigation-menu .navigation-items:hover .title{color: var(--color02);}

/*----- Navigation - Top Navigation -----*/
.tabbed-navigation{position: sticky;top: var(--height);width: 100%;padding: 0 30px;margin: 0;list-style: none;height: 60px;display: flex;align-items: center;gap: 40px;background-color: var(--shades07);z-index: 7;overflow: auto;}
.tabbed-navigation .navigation-items{ position: relative; height: 60px;display: flex;align-items: center;justify-content: center;color: var(--shades05);font-weight: 400;font-size: 14px; letter-spacing: 0.25px;white-space: nowrap;}
.tabbed-navigation .navigation-items:before{content: ''; position: absolute; width: 100%; height: 5px; border-radius: 20px; background-color: var(--color02); left: 0; bottom: 0; transform: scale(0,1); transition: all 0.3s ease;}
.tabbed-navigation .navigation-items.active, 
.tabbed-navigation .navigation-items:hover{color: var(--shades01);}
.tabbed-navigation .navigation-items.active:before,
.tabbed-navigation .navigation-items:hover:before{transform: scale(1,1);}


/*----- Partials - Brands -----*/
.brand{ width: 70px; height: 70px; overflow: hidden;}
.brand .logo{display: block; width: 100%; height: 100%;}


/*----- Partials - Page Title -----*/
.page-title{font-size: 18px; margin: 0; font-weight: 300; text-transform: uppercase; color: var(--shades01); letter-spacing: 0.3px;}


/*######### COMPONENTS END #########*/


/*######### MAIN #########*/ 
.main{position: relative; display: block; min-height: 100vh; background-color: var(--shades03); width: 100%; display: flex; justify-content: flex-end;}
.main .main-aside{position: fixed;top: 0; left: 0; z-index: 9; width: 140px; height: 100vh; display: flex;}
.main .main-content{position: relative; width: 100%; max-width: calc(100% - 140px); }
.main .page-content{position: relative; width: 100%; padding: 30px;}
.page-content .input-items .form-control.date-picker{height: 40px;border: 1px solid var(--shades04);}
/*######### MAIN END #########*/


/*######### LAYOUT #########*/ 
.authentication{position: relative; width: 100%; height: 100vh; padding:0; overflow: hidden;display: flex;flex-direction: column;justify-content: center;align-items: center;}
.authentication:before{background-color: var(--color02);content: "";height: 100%;left: 60%;position: absolute;top: 0;-webkit-transform: skewX(-40deg);transform: skewX(-40deg);width: 100%;z-index: -1;}
.authentication .authentication-image{width: 100%;max-width: 500px; margin: auto;}
.authentication .authentication-footer{padding: 20px 0;display: flex;justify-content: center;}
.authentication .copyright{color: var(--shades01);font-weight: 300;font-size: 13px;letter-spacing: 0.5px;}
/*######### LAYOUT END #########*/ 


/*######### SHARED #########*/ 
/*----- Default Sidebar -----*/
.sidepanel{display: flex; flex-direction: column; position: relative; width: 100%; background-color: var(--shades02);}
.site-identity{margin: 0 auto; position: relative; padding: 10px 0;display: flex;align-items: center;justify-content: center;height: var(--height);}
.side-navigation{padding: 20px 0; border-top:1px solid var(--shades08); overflow: auto; max-height: calc(100vh - 140px);}

/*----- Default Header -----*/
header{position: sticky; top: 0; left: 0; z-index: 8; background-color: var(--shades08); display: flex; align-items: center; width: 100%; height: var(--height); padding: 0 30px;}
header .header-inner{display: flex; align-items: center;width: 100%;}
header .header-identity{width: 100%; max-width: 300px;}
header .header-store{width: 100%; max-width: 480px;}
header .header-options{margin: 0 0 0 auto; display: flex; align-items: center; gap: 20px;}

/*----- Default Header - Store -----*/
.store{position: relative; width:100%;display: flex; align-items: center; gap: 10px;}
.store .store-switch{width: 60px;min-width: 60px; position: relative; overflow: hidden;}
.store .store-info{width: 100%;}
.store .store-info .store-title{font-size: 16px;font-weight: 500;color: var(--shades01);margin: 0;}
.store .store-info .store-text{font-size: 11px;font-weight: 300;color: var(--shades05);line-height: 1.2;margin: 2px 0 0;}

/*######### SHARED END #########*/ 


/*----- Authentication Page -----*/
.authentication-content{width: 100%;max-width: 400px;margin: auto;}
.authentication-content .brand{width: 120px;height: 120px;overflow: hidden; margin: 0 auto 40px;}
.authentication-content .brand .logo{display: block;width: 100%;height: 100%;}
.authentication-content .authentication-inner{position: relative;background-color: var(--shades01);padding: 30px;box-shadow: rgb(0 0 0 / 10%) 0 2px 5px;border-radius: 20px;}
.authentication-content .authentication-inner .authentication-title{font-size: 24px;font-weight: 600;color: var(--shades07); text-align: center;}
.authentication-content .authentication-inner .authentication-text{font-size: 14px;font-weight: 300;color: var(--shades05); text-align: center;}



/*----- Page Filters -----*/
.page-filter{position: relative;}
.page-filter .input-filters{display: flex; flex-wrap: wrap; align-items: center; margin: 0 -10px;}
.page-filter .input-filters .input-items{margin: 0 10px 20px; width: 100%; max-width: calc(20% - 20px);}
.page-filter .input-filters .input-items.items-end{display: flex; justify-content: flex-end;}
.page-filter .radio-filters{display: flex; flex-wrap: wrap; column-gap: 30px; row-gap: 10px;}

/*----- Menu List -----*/
.menu-list{position: relative; margin: 0 0 40px;}
.menu-list .menu-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.menu-list .menu-item+.menu-item{margin: 16px 0 0;}
.menu-list .menu-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.menu-list .menu-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.menu-list .menu-type{position: relative;}
.menu-list .type{display: flex;align-items: center;gap: 6px;}
.menu-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.menu-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.menu-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.menu-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.menu-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.menu-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.menu-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.menu-list .menu-options{display: flex;align-items: center; gap:10px; margin-left: auto;}
.menu-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.menu-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.menu-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.menu-list .status.published:before,
.menu-list .status.published:after{background-color: var(--colorSuccess);}
.menu-list .status.draft:before,
.menu-list .status.draft:after{background-color: var(--colorWaning);}
.menu-list .menu-action{position: relative;}
.menu-list .menu-content{position: relative;}
.menu-list .menu-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.menu-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.menu-list .menu-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.menu-list .menu-categories span+span:before{content:','; padding-right: 4px;}
.menu-list .menu-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.menu-list .menu-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.menu-list .menu-metas .meta-item.full{width:100%}
.menu-list .menu-metas .meta-icon{font-size: 26px;color: var(--color02);}
.menu-list .menu-metas .meta-content{position: relative;line-height: 1.2;}
.menu-list .menu-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.menu-list .menu-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.menu-list .menu-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.menu-list .menu-stock{position: relative;}
.menu-list .menu-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.menu-list .menu-price{display: flex;align-items: center;gap: 10px; margin-left: auto;}
.menu-list .menu-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.menu-list .menu-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.menu-list .menu-info{display: flex;align-items: center;flex-wrap: wrap;}
.menu-list .menu-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.menu-list .menu-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.menu-list .menu-info .info-data{color: var(--shades08);}


/*----- Addons List -----*/
.addons-list{position: relative; margin: 0 0 40px;}
.addons-list .addons-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;margin-bottom: 16px;}
.addons-list .addons-item+.addons-item{margin: 16px 0 0;}
.addons-list .addons-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.addons-list .addons-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.addons-list .addons-options{display: flex;align-items: center; gap:10px; margin-left: auto; position: absolute;top: -5px;right: 0; z-index: 1;}
.addons-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.addons-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.addons-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.addons-list .status.published:before, .addons-list .status.published:after{background-color: var(--colorSuccess);}
.addons-list .status.draft:before, .addons-list .status.draft:after{background-color: var(--colorWaning);}
.addons-list .addons-action{position: relative;}
.addons-list .addons-content{position: relative;}
.addons-list .addons-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.addons-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.addons-list .addons-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.addons-list .addons-categories span+span:before{content:','; padding-right: 4px;}
.addons-list .addons-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.addons-list .addons-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.addons-list .addons-metas .meta-item.full{width:100%;}
.addons-list .addons-metas .meta-icon{font-size: 26px;color: var(--color02);}
.addons-list .addons-metas .meta-content{position: relative;line-height: 1.2;}
.addons-list .addons-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.addons-list .addons-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.addons-list .addons-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.addons-list .addons-price{display: flex;align-items: center;gap: 10px;margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.addons-list .addons-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.addons-list .addons-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.addons-list .addons-info{display: flex;align-items: center;flex-wrap: wrap;}
.addons-list .addons-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.addons-list .addons-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.addons-list .addons-info .info-data{color: var(--shades08);}



/*----- Merchandise List -----*/
.merchandise-list{position: relative; margin: 0 0 40px;}
.merchandise-list .merchandise-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.merchandise-list .merchandise-item+.merchandise-item{margin: 16px 0 0;}
.merchandise-list .merchandise-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.merchandise-list .merchandise-item-row.unset-align{align-items: unset;}
.merchandise-list .merchandise-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.merchandise-list .merchandise-options{display: flex;align-items: center; gap:10px; margin-left: auto; position: absolute;top: -5px;right: 0; z-index: 1;}
.merchandise-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.merchandise-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.merchandise-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.merchandise-list .status.published:before,
.merchandise-list .status.published:after{background-color: var(--colorSuccess);}
.merchandise-list .status.draft:before,
.merchandise-list .status.draft:after{background-color: var(--colorWaning);}
.merchandise-list .merchandise-action{position: relative;}
.merchandise-list .merchandise-image{width: 100px; min-width: 100px; height: 100px; overflow: hidden; border-radius: 6px; border:1px solid var(--shades05);}
.merchandise-list .merchandise-content{position: relative;margin-right: auto; display: flex; flex-direction: column; justify-content: space-between; padding: 4px 0;}
.merchandise-list .merchandise-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.merchandise-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.merchandise-list .merchandise-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.merchandise-list .merchandise-categories span+span:before{content:','; padding-right: 4px;}
.merchandise-list .merchandise-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.merchandise-list .merchandise-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.merchandise-list .merchandise-metas .meta-item.full{width:100%;}
.merchandise-list .merchandise-metas .meta-icon{font-size: 26px;color: var(--color02);}
.merchandise-list .merchandise-metas .meta-content{position: relative;line-height: 1.2;}
.merchandise-list .merchandise-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.merchandise-list .merchandise-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.merchandise-list .merchandise-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.merchandise-list .merchandise-stock{position: relative;}
.merchandise-list .merchandise-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.merchandise-list .merchandise-info{display: flex;align-items: center;flex-wrap: wrap;}
.merchandise-list .merchandise-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.merchandise-list .merchandise-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.merchandise-list .merchandise-info .info-data{color: var(--shades08);}

/*----- Coupon List -----*/
.coupon-list{position: relative; margin: 0 0 40px;}
.coupon-list .coupon-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.coupon-list .coupon-item+.coupon-item{margin: 16px 0 0;}
.coupon-list .coupon-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.coupon-list .coupon-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.coupon-list .coupon-type{position: relative;}
.coupon-list .type{display: flex;align-items: center;gap: 6px;}
.coupon-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.coupon-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.coupon-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.coupon-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.coupon-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.coupon-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.coupon-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.coupon-list .coupon-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.coupon-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.coupon-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.coupon-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.coupon-list .status.published:before,
.coupon-list .status.published:after{background-color: var(--colorSuccess);}
.coupon-list .status.draft:before,
.coupon-list .status.draft:after{background-color: var(--colorWaning);}
.coupon-list .coupon-action{position: relative;}
.coupon-list .coupon-content{position: relative; padding-right: 120px;}
.coupon-list .coupon-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.coupon-list .coupon-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.coupon-list .coupon-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.coupon-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.coupon-list .coupon-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.coupon-list .coupon-categories span+span:before{content:','; padding-right: 4px;}
.coupon-list .coupon-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.coupon-list .coupon-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.coupon-list .coupon-metas .meta-item.full{width:100%}
.coupon-list .coupon-metas .meta-icon{font-size: 26px;color: var(--color02);}
.coupon-list .coupon-metas .meta-content{position: relative;line-height: 1.2;}
.coupon-list .coupon-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.coupon-list .coupon-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.coupon-list .coupon-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.coupon-list .coupon-stock{position: relative;}
.coupon-list .coupon-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.coupon-list .coupon-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.coupon-list .coupon-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.coupon-list .coupon-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.coupon-list .coupon-info{display: flex;align-items: center;flex-wrap: wrap;}
.coupon-list .coupon-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.coupon-list .coupon-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.coupon-list .coupon-info .info-data{color: var(--shades08);}


/*----- Category List -----*/
.category-list{position: relative; margin: 0 0 40px;}
.category-list .category-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.category-list .category-item+.category-item{margin: 16px 0 0;}
.category-list .category-item-row{display: flex;justify-content: space-between; align-items: flex-start;}
.category-list .category-content .category-image{width: 70px;height: 70px;padding: 5px;border: 1px solid var(--bs-gray-300);border-radius: 5px;display: flex;align-items: center;justify-content: center;}
.category-list .category-content .category-image img{width: 100%;height: 100%;object-fit: cover;}
.category-list .category-content .category-details{width: 100%;max-width: calc(100% - 90px);margin-left: 20px;}
.category-list .category-content .category-details .category-descriptions{display: flex;flex-direction: column;margin-top: 12px;}
.category-list .category-content .category-details .category-descriptions span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.category-list .category-content .category-details .category-descriptions .des{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades08);}
.category-list .category-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.category-list .category-type{position: relative;}
.category-list .category-options{display: flex;align-items: center; gap:10px}
.category-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.category-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.category-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.category-list .status.published:before,
.category-list .status.published:after{background-color: var(--colorSuccess);}
.category-list .status.draft:before,
.category-list .status.draft:after{background-color: var(--colorWaning);}
.category-list .category-action{position: relative;}
.category-list .category-content{position: relative;display: flex;}
.category-list .category-title{font-size: 18px;font-weight: 500;line-height: 1;display: flex;align-items: center;}
.category-list .category-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.category-list .category-categories span+span:before{content:','; padding-right: 4px;}
.category-list .category-info{display: flex;align-items: center;flex-wrap: wrap;}
.category-list .category-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.category-list .category-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.category-list .category-info .info-data{color: var(--shades08);}


/*----- Allergies List -----*/
.allergies-list{position: relative; margin: 0 0 40px;}
.allergies-list .allergies-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.allergies-list .allergies-item+.allergies-item{margin: 16px 0 0;}
.allergies-list .allergies-item-row{display: flex;justify-content: space-between; align-items: flex-start;}
.allergies-list .allergies-content .allergies-image{width: 50px;height: 50px;padding: 5px;border: 1px solid var(--bs-gray-300);border-radius: 5px;display: flex;align-items: center;justify-content: center;}
.allergies-list .allergies-content .allergies-image img{width: 100%;height: 100%;object-fit: cover;}
.allergies-list .allergies-content .allergies-details{width: 100%;max-width: calc(100% - 70px);margin-left: 20px;}
.allergies-list .allergies-content .allergies-details .allergies-descriptions{display: flex;flex-direction: column;margin-top: 12px;}
.allergies-list .allergies-content .allergies-details .allergies-descriptions span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.allergies-list .allergies-content .allergies-details .allergies-descriptions .des{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades08);}
.allergies-list .allergies-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.allergies-list .allergies-type{position: relative;}
.allergies-list .allergies-options{display: flex;align-items: center; gap:10px}
.allergies-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.allergies-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.allergies-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.allergies-list .status.published:before,
.allergies-list .status.published:after{background-color: var(--colorSuccess);}
.allergies-list .status.draft:before,
.allergies-list .status.draft:after{background-color: var(--colorWaning);}
.allergies-list .allergies-action{position: relative;}
.allergies-list .allergies-content{position: relative;display: flex;align-items: center;}
.allergies-list .allergies-title{font-size: 18px;font-weight: 500;line-height: 1;display: flex;align-items: center;}
.allergies-list .allergies-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.allergies-list .allergies-categories span+span:before{content:','; padding-right: 4px;}
.allergies-list .allergies-info{display: flex;align-items: center;flex-wrap: wrap;}
.allergies-list .allergies-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.allergies-list .allergies-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.allergies-list .allergies-info .info-data{color: var(--shades08);}


/*----- Spice Level List -----*/
.spice-level-list{position: relative; margin: 0 0 40px;}
.spice-level-list .spice-level-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.spice-level-list .spice-level-item+.spice-level-item{margin: 16px 0 0;}
.spice-level-list .spice-level-item-row{display: flex;justify-content: space-between; align-items: flex-start;}
.spice-level-list .spice-level-content .spice-level-image{width: 70px;height: 70px;padding: 5px;border: 1px solid var(--bs-gray-300);border-radius: 5px;display: flex;align-items: center;justify-content: center;}
.spice-level-list .spice-level-content .spice-level-image img{width: 100%;height: 100%;object-fit: contain;}
.spice-level-list .spice-level-content .spice-level-details{width: 100%;max-width: calc(100% - 90px);margin-left: 20px;}
.spice-level-list .spice-level-content .spice-level-details .spice-level-descriptions{display: flex;flex-direction: column;margin-top: 12px;}
.spice-level-list .spice-level-content .spice-level-details .spice-level-descriptions span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.spice-level-list .spice-level-content .spice-level-details .spice-level-descriptions .des{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades08);}
.spice-level-list .spice-level-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.spice-level-list .spice-level-type{position: relative;}
.spice-level-list .spice-level-options{display: flex;align-items: center; gap:10px}
.spice-level-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.spice-level-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.spice-level-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.spice-level-list .status.published:before,
.spice-level-list .status.published:after{background-color: var(--colorSuccess);}
.spice-level-list .status.draft:before,
.spice-level-list .status.draft:after{background-color: var(--colorWaning);}
.spice-level-list .spice-level-action{position: relative;}
.spice-level-list .spice-level-content{position: relative;display: flex;align-items: center;}
.spice-level-list .spice-level-title{font-size: 18px;font-weight: 500;line-height: 1;display: flex;align-items: center;}
.spice-level-list .spice-level-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.spice-level-list .spice-level-categories span+span:before{content:','; padding-right: 4px;}
.spice-level-list .spice-level-info{display: flex;align-items: center;flex-wrap: wrap;}
.spice-level-list .spice-level-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.spice-level-list .spice-level-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.spice-level-list .spice-level-info .info-data{color: var(--shades08);}


/*----- Diets List -----*/
.diets-list{position: relative; margin: 0 0 40px;}
.diets-list .diets-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.diets-list .diets-item+.diets-item{margin: 16px 0 0;}
.diets-list .diets-item-row{display: flex;justify-content: space-between; align-items: flex-start;}
.diets-list .diets-content .diets-image{width: 50px;height: 50px;padding: 5px;border: 1px solid var(--bs-gray-300);border-radius: 5px;display: flex;align-items: center;justify-content: center;}
.diets-list .diets-content .diets-image img{width: 100%;height: 100%;object-fit: contain;}
.diets-list .diets-content .diets-details{width: 100%;max-width: calc(100% - 70px);margin-left: 20px;}
.diets-list .diets-content .diets-details .diets-descriptions{display: flex;flex-direction: column;margin-top: 12px;}
.diets-list .diets-content .diets-details .diets-descriptions span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.diets-list .diets-content .diets-details .diets-descriptions .des{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades08);}
.diets-list .diets-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.diets-list .diets-type{position: relative;}
.diets-list .diets-options{display: flex;align-items: center; gap:10px}
.diets-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.diets-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.diets-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.diets-list .status.published:before,
.diets-list .status.published:after{background-color: var(--colorSuccess);}
.diets-list .status.draft:before,
.diets-list .status.draft:after{background-color: var(--colorWaning);}
.diets-list .diets-action{position: relative;}
.diets-list .diets-content{position: relative;display: flex;align-items: center;}
.diets-list .diets-title{font-size: 18px;font-weight: 500;line-height: 1;display: flex;align-items: center;}
.diets-list .diets-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.diets-list .diets-categories span+span:before{content:','; padding-right: 4px;}
.diets-list .diets-info{display: flex;align-items: center;flex-wrap: wrap;}
.diets-list .diets-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.diets-list .diets-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.diets-list .diets-info .info-data{color: var(--shades08);}



/*----- Take Off Time List -----*/
.take-off-list{position: relative; margin: 0 0 40px;}
.take-off-list .take-off-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.take-off-list .take-off-item+.take-off-item{margin: 16px 0 0;}
.take-off-list .take-off-item-row{display: flex;justify-content: space-between; align-items: flex-start;}
.take-off-list .take-off-content .take-off-image{width: 50px;height: 50px;padding: 5px;border: 1px solid var(--bs-gray-300);border-radius: 5px;display: flex;align-items: center;justify-content: center;}
.take-off-list .take-off-content .take-off-image img{width: 100%;height: 100%;object-fit: contain;}
.take-off-list .take-off-content .take-off-details{}
.take-off-list .take-off-content .take-off-details .take-off-descriptions{display: flex;flex-direction: column;margin-top: 12px;}
.take-off-list .take-off-content .take-off-details span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.take-off-list .take-off-content .take-off-details .take-off-descriptions .des{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades08);}
.take-off-list .take-off-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.take-off-list .take-off-type{position: relative;}
.take-off-list .take-off-options{display: flex;align-items: center; gap:10px}
.take-off-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.take-off-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.take-off-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.take-off-list .status.published:before,
.take-off-list .status.published:after{background-color: var(--colorSuccess);}
.take-off-list .status.draft:before,
.take-off-list .status.draft:after{background-color: var(--colorWaning);}
.take-off-list .take-off-action{position: relative;}
.take-off-list .take-off-content{position: relative;display: flex;align-items: center;}
.take-off-list .take-off-title{font-size: 18px;font-weight: 500;line-height: 1;display: flex;align-items: center;}
.take-off-list .take-off-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.take-off-list .take-off-categories span+span:before{content:','; padding-right: 4px;}
.take-off-list .take-off-info{display: flex;align-items: center;flex-wrap: wrap;}
.take-off-list .take-off-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.take-off-list .take-off-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.take-off-list .take-off-info .info-data{color: var(--shades08);}


/*----- Blogs List -----*/
.blogs-list{position: relative; margin: 0 0 40px;}
.blogs-list .blogs-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.blogs-list .blogs-item+.blogs-item{margin: 16px 0 0;}
.blogs-list .blogs-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.blogs-list .blogs-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.blogs-list .blogs-type{position: relative;}
.blogs-list .type{display: flex;align-items: center;gap: 6px;}
.blogs-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.blogs-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.blogs-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.blogs-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.blogs-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.blogs-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.blogs-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.blogs-list .blogs-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.blogs-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.blogs-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.blogs-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.blogs-list .status.published:before,
.blogs-list .status.published:after{background-color: var(--colorSuccess);}
.blogs-list .status.draft:before,
.blogs-list .status.draft:after{background-color: var(--colorWaning);}
.blogs-list .blogs-action{position: relative;}
.blogs-list .blogs-content{position: relative; padding-right: 120px;}
.blogs-list .blogs-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.blogs-list .blogs-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.blogs-list .blogs-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.blogs-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.blogs-list .blogs-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.blogs-list .blogs-categories span+span:before{content:','; padding-right: 4px;}
.blogs-list .blogs-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.blogs-list .blogs-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.blogs-list .blogs-metas .meta-item.full{width:100%}
.blogs-list .blogs-metas .meta-icon{font-size: 26px;color: var(--color02);}
.blogs-list .blogs-metas .meta-content{position: relative;line-height: 1.2;}
.blogs-list .blogs-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.blogs-list .blogs-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.blogs-list .blogs-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.blogs-list .blogs-stock{position: relative;}
.blogs-list .blogs-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.blogs-list .blogs-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.blogs-list .blogs-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.blogs-list .blogs-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.blogs-list .blogs-info{display: flex;align-items: center;flex-wrap: wrap;}
.blogs-list .blogs-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.blogs-list .blogs-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.blogs-list .blogs-info .info-data{color: var(--shades08);}


/*----- Comments List -----*/
.comment-list{position: relative; margin: 0 0 40px;}
.comment-list .comment-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.comment-list .comment-item+.comment-item{margin: 16px 0 0;}
.comment-list .comment-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.comment-list .comment-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.comment-list .comment-type{position: relative;}
.comment-list .type{display: flex;align-items: center;gap: 6px;}
.comment-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.comment-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.comment-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.comment-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.comment-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.comment-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.comment-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.comment-list .comment-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.comment-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.comment-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.comment-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.comment-list .status.published:before,
.comment-list .status.published:after{background-color: var(--colorSuccess);}
.comment-list .status.draft:before,
.comment-list .status.draft:after{background-color: var(--colorWaning);}
.comment-list .comment-action{position: relative;}
.comment-list .comment-content{position: relative; padding-right: 120px;}
.comment-list .comment-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.comment-list .comment-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.comment-list .comment-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.comment-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.comment-list .comment-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.comment-list .comment-categories span+span:before{content:','; padding-right: 4px;}
.comment-list .comment-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.comment-list .comment-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.comment-list .comment-metas .meta-item.full{width:100%}
.comment-list .comment-metas .meta-icon{font-size: 26px;color: var(--color02);}
.comment-list .comment-metas .meta-content{position: relative;line-height: 1.2;}
.comment-list .comment-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.comment-list .comment-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.comment-list .comment-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.comment-list .comment-stock{position: relative;}
.comment-list .comment-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.comment-list .comment-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.comment-list .comment-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.comment-list .comment-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.comment-list .comment-info{display: flex;align-items: center;flex-wrap: wrap;}
.comment-list .comment-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.comment-list .comment-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.comment-list .comment-info .info-data{color: var(--shades08);}


/*----- Author List -----*/
.author-list{position: relative; margin: 0 0 40px;}
.author-list .author-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.author-list .author-item+.author-item{margin: 16px 0 0;}
.author-list .author-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.author-list .author-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.author-list .author-type{position: relative;}
.author-list .type{display: flex;align-items: center;gap: 6px;}
.author-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.author-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.author-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.author-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.author-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.author-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.author-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.author-list .author-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.author-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.author-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.author-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.author-list .status.published:before,
.author-list .status.published:after{background-color: var(--colorSuccess);}
.author-list .status.draft:before,
.author-list .status.draft:after{background-color: var(--colorWaning);}
.author-list .author-action{position: relative;}
.author-list .author-content{position: relative; padding-right: 120px;}
.author-list .author-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.author-list .author-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.author-list .author-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.author-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.author-list .author-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.author-list .author-categories span+span:before{content:','; padding-right: 4px;}
.author-list .author-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.author-list .author-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.author-list .author-metas .meta-item.full{width:100%}
.author-list .author-metas .meta-icon{font-size: 26px;color: var(--color02);}
.author-list .author-metas .meta-content{position: relative;line-height: 1.2;}
.author-list .author-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.author-list .author-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.author-list .author-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.author-list .author-stock{position: relative;}
.author-list .author-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.author-list .author-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.author-list .author-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.author-list .author-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.author-list .author-info{display: flex;align-items: center;flex-wrap: wrap;}
.author-list .author-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.author-list .author-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.author-list .author-info .info-data{color: var(--shades08);}


/*----- FAQs List -----*/
.faqs-list{position: relative; margin: 0 0 40px;}
.faqs-list .faqs-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.faqs-list .faqs-item+.faqs-item{margin: 16px 0 0;}
.faqs-list .faqs-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.faqs-list .faqs-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.faqs-list .faqs-type{position: relative;}
.faqs-list .type{display: flex;align-items: center;gap: 6px;}
.faqs-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.faqs-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.faqs-list .faqs-content .faqs-details span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.faqs-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.faqs-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.faqs-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.faqs-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.faqs-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.faqs-list .faqs-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.faqs-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.faqs-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.faqs-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.faqs-list .status.published:before,
.faqs-list .status.published:after{background-color: var(--colorSuccess);}
.faqs-list .status.draft:before,
.faqs-list .status.draft:after{background-color: var(--colorWaning);}
.faqs-list .faqs-action{position: relative;}
.faqs-list .faqs-content{position: relative; padding-right: 120px;}
.faqs-list .faqs-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.faqs-list .faqs-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.faqs-list .faqs-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.faqs-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.faqs-list .faqs-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.faqs-list .faqs-categories span+span:before{content:','; padding-right: 4px;}
.faqs-list .faqs-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.faqs-list .faqs-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.faqs-list .faqs-metas .meta-item.full{width:100%}
.faqs-list .faqs-metas .meta-icon{font-size: 26px;color: var(--color02);}
.faqs-list .faqs-metas .meta-content{position: relative;line-height: 1.2;}
.faqs-list .faqs-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.faqs-list .faqs-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.faqs-list .faqs-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.faqs-list .faqs-stock{position: relative;}
.faqs-list .faqs-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.faqs-list .faqs-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.faqs-list .faqs-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.faqs-list .faqs-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.faqs-list .faqs-info{display: flex;align-items: center;flex-wrap: wrap;}
.faqs-list .faqs-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.faqs-list .faqs-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.faqs-list .faqs-info .info-data{color: var(--shades08);}


/*----- FAQ FeedbackList -----*/
.blogs-list{position: relative; margin: 0 0 40px;}
.blogs-list .blogs-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px; margin-top: 16px}
.blogs-list .blogs-item+.blogs-item{margin: 16px 0 0;}
.blogs-list .blogs-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.blogs-list .blogs-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.blogs-list .blogs-type{position: relative;}
.blogs-list .type{display: flex;align-items: center;gap: 6px;}
.blogs-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.blogs-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.blogs-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.blogs-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.blogs-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.blogs-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.blogs-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.blogs-list .blogs-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.blogs-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.blogs-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.blogs-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.blogs-list .status.published:before,
.blogs-list .status.published:after{background-color: var(--colorSuccess);}
.blogs-list .status.draft:before,
.blogs-list .status.draft:after{background-color: var(--colorWaning);}
.blogs-list .blogs-action{position: relative;}
.blogs-list .blogs-content{position: relative; padding-right: 120px;}
.blogs-list .blogs-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.blogs-list .blogs-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.blogs-list .blogs-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.blogs-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.blogs-list .blogs-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.blogs-list .blogs-categories span+span:before{content:','; padding-right: 4px;}
.blogs-list .blogs-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.blogs-list .blogs-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.blogs-list .blogs-metas .meta-item.full{width:100%}
.blogs-list .blogs-metas .meta-icon{font-size: 26px;color: var(--color02);}
.blogs-list .blogs-metas .meta-content{position: relative;line-height: 1.2;}
.blogs-list .blogs-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.blogs-list .blogs-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.blogs-list .blogs-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.blogs-list .blogs-stock{position: relative;}
.blogs-list .blogs-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.blogs-list .blogs-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.blogs-list .blogs-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.blogs-list .blogs-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.blogs-list .blogs-info{display: flex;align-items: center;flex-wrap: wrap;}
.blogs-list .blogs-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.blogs-list .blogs-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.blogs-list .blogs-info .info-data{color: var(--shades08);}

/*----- Orders List -----*/
.orders-list{position: relative;margin: 0 0 40px;}
.order-stat{position: relative;display: flex;margin: 10px -20px;}
.order-stat .stat-item{width: 100%;max-width: calc(25% - 10px);position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;display: flex;justify-content: space-between;align-items: center;margin: 20px;}
.order-stat .stat-item .icon{font-size: 50px;color: var(--color02);}
.order-stat .stat-item .stat-content{text-align: right;}
.order-stat .stat-item .stat-content .other-data{display: flex;}
.order-stat .stat-item .stat-content .other-data .info-other+ .info-other{margin-left: 10px;}
.order-stat .stat-item .stat-content .other-data .info-other{display: flex;font-size: 11px;text-transform: capitalize;font-weight: 500;color: var(--colorDanger);}
.order-stat .stat-item .stat-content .number{font-size: 20px;color: var(--color02);font-weight: 500;}
.order-stat .stat-item .stat-content .title{color: var(--shades06);font-size: 14px;text-transform: capitalize;}
.orders-list .orders-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.orders-list .orders-item+.orders-item{margin: 16px 0 0;}
.orders-list .orders-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px; }
.orders-list .orders-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.orders-list .orders-options{display: flex;align-items: center;gap: 10px;margin-left: auto;position: absolute;top: -5px;right: 0;z-index: 1;}
.orders-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.orders-list .orders-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.orders-list .orders-type{position: relative;}
.orders-list .orders-info{display: flex;align-items: center;flex-wrap: wrap;}
.orders-list .orders-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.orders-list .orders-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.orders-list .orders-info .info-data{color: var(--shades08);}
.orders-list .orders-content{position: relative; padding-right: 200px;display: flex;justify-content: space-between;cursor: pointer;}
.orders-list .orders-content .status-part{margin-left: 5px;}
.orders-list .orders-content .status-part span{font-size: 10px;padding: 7px;border-radius: 50px;display: flex;align-items: center;color: var(--shades01);font-weight: 400;letter-spacing: 0.3px;}
.orders-list .orders-content .status-part .pending{background: var(--colorWaning);}
.orders-list .orders-content .status-part .confirmed{background: var(--colorSuccess);}
.orders-list .orders-content .status-part .rejected{background: var(--colorDanger);}
.orders-list .orders-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.orders-list .orders-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.orders-list .orders-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.orders-list .orders-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.orders-list .orders-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.orders-list .orders-metas .meta-item.full{width:100%}
.orders-list .orders-metas .meta-icon{font-size: 26px;color: var(--color02);}
.orders-list .orders-metas .meta-content{position: relative;line-height: 1.2;}
.orders-list .orders-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.orders-list .orders-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.orders-list .orders-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.orders-list .order-customer-info{}
.orders-list .order-customer-info .title{font-size: 12px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.orders-list .order-customer-info .info-customer{margin-top: 5px;}
.orders-list .order-customer-info .info-customer .name{font-size: 16px;font-weight: 600;line-height: 1;display: flex;text-transform: capitalize;align-items: center;margin-bottom: 5px;}
.orders-list .order-customer-info .info-customer .other-data{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 400;color: var(--shades06);}
.orders-list .order-customer-info .info-customer .other-data.type{margin-top: 5px;  color: var(--shades08);font-weight: 600;}
.orders-list .order-customer-info .info-customer .other-data.type+ .other-data.type{margin-top: 0;}
.orders-list .order-customer-info .info-customer .other-data.type span{  
  font-weight: 500;color: var(--shades06);}
.orders-list .orders-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.orders-list .orders-price .price{font-size: 24px;font-weight: 600;color: var(--color02);display: flex;flex-direction: column;align-items: flex-end;}
.orders-list .orders-price .price span{display: flex;align-items: center;gap: 5px;font-size: 14px;font-weight: 500;color: var(--shades06);}
.orders-list .order-customer-info .info-customer .name .member-data{font-size: 10px;font-weight: 400;padding: 4px 6px;margin-left: 8px;border-radius: 5px;letter-spacing: 0.3px;}
.orders-list .order-customer-info .info-customer .name .member-data.guest{ background: var(--color02);color: var(--shades01);}
.orders-list .order-customer-info .info-customer .name .member-data.member{ background: var(--colorSuccess);color: var(--shades01);}

/*----- Jobs List -----*/
.applications-list{position: relative;margin: 0 0 40px;}
.order-stat{position: relative;display: flex;margin: 10px -20px;}
.order-stat .stat-item{width: 100%;max-width: calc(25% - 10px);position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;display: flex;justify-content: space-between;align-items: center;margin: 20px;}
.order-stat .stat-item .icon{font-size: 50px;color: var(--color02);}
.order-stat .stat-item .stat-content{text-align: right;}
.order-stat .stat-item .stat-content .other-data{display: flex;}
.order-stat .stat-item .stat-content .other-data .info-other+ .info-other{margin-left: 10px;}
.order-stat .stat-item .stat-content .other-data .info-other{display: flex;font-size: 11px;text-transform: capitalize;font-weight: 500;color: var(--colorDanger);}
.order-stat .stat-item .stat-content .number{font-size: 20px;color: var(--shades02);font-weight: 600;}
.order-stat .stat-item .stat-content .title{color: var(--shades06);font-size: 14px;text-transform: capitalize;}
.applications-list .applications-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.applications-list .applications-item+.applications-item{margin: 16px 0 0;}
.applications-list .applications-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.applications-list .applications-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.applications-list .applications-options{display: flex;align-items: center;gap: 10px;margin-left: auto;position: absolute;top: -5px;right: 0;z-index: 1;}
.applications-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.applications-list .applications-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.applications-list .applications-type{position: relative;}
.applications-list .applications-info{display: flex;align-items: center;flex-wrap: wrap;}
.applications-list .applications-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.applications-list .applications-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.applications-list .applications-info .info-data{color: var(--shades08);}
.applications-list .applications-content{position: relative; padding-right: 200px;display: flex;justify-content: space-between;}
.applications-list .applications-content .status-part{margin-left: 5px;}
.applications-list .applications-content .status-part span{font-size: 10px;padding: 7px;border-radius: 50px;display: flex;align-items: center;color: var(--shades01);font-weight: 400;letter-spacing: 0.3px;}
.applications-list .applications-content .status-part .pending{background: var(--colorWaning);}
.applications-list .applications-content .status-part .confirmed{background: var(--colorSuccess);}
.applications-list .applications-content .status-part .rejected{background: var(--colorDanger);}
.applications-list .applications-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.applications-list .applications-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.applications-list .applications-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.applications-list .applications-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.applications-list .applications-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.applications-list .applications-metas .meta-item.full{width:100%}
.applications-list .applications-metas .meta-icon{font-size: 26px;color: var(--color02);}
.applications-list .applications-metas .meta-content{position: relative;line-height: 1.2;}
.applications-list .applications-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.applications-list .applications-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.applications-list .applications-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.applications-list .order-customer-info{}
.applications-list .order-customer-info .title{font-size: 12px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.applications-list .order-customer-info .info-customer{margin-top: 5px;}
.applications-list .order-customer-info .info-customer .name{font-size: 16px;font-weight: 600;line-height: 1;display: flex;text-transform: capitalize;align-items: center;margin-bottom: 5px;}
.applications-list .order-customer-info .info-customer .other-data{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 400;color: var(--shades06);}
.applications-list .order-customer-info .info-customer .other-data.type{margin-top: 5px;  color: var(--shades08);font-weight: 600;}
.applications-list .order-customer-info .info-customer .other-data.type+ .other-data.type{margin-top: 0;}
.applications-list .order-customer-info .info-customer .other-data.type span{  
  font-weight: 500;color: var(--shades06);}
.applications-list .applications-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.applications-list .applications-price .price{font-size: 24px;font-weight: 600;color: var(--color02);display: flex;flex-direction: column;align-items: flex-end;}
.applications-list .applications-price .price span{display: flex;align-items: center;gap: 5px;font-size: 14px;font-weight: 500;color: var(--shades06);}


/*----- Location List -----*/
.location-list{position: relative; margin: 0 0 40px;}
.location-list .location-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.location-list .location-item+.location-item{margin: 16px 0 0;}
.location-list .location-item-row{display: flex;justify-content: space-between; align-items: flex-start;}
.location-list .location-content .location-image{width: 50px;height: 50px;padding: 5px;border: 1px solid var(--bs-gray-300);border-radius: 5px;display: flex;align-items: center;justify-content: center;}
.location-list .location-content .location-image img{width: 100%;height: 100%;object-fit: contain;}
.location-list .location-content .location-details{}
.location-list .location-content .location-details .location-descriptions{display: flex;flex-direction: column;margin-top: 12px;}
.location-list .location-content .location-details span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.location-list .location-content .location-details .location-descriptions .des{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades08);}
.location-list .location-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.location-list .location-type{position: relative;}
.location-list .location-options{display: flex;align-items: center; gap:10px}
.location-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.location-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.location-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.location-list .status.published:before,
.location-list .status.published:after{background-color: var(--colorSuccess);}
.location-list .status.draft:before,
.location-list .status.draft:after{background-color: var(--colorWaning);}
.location-list .location-action{position: relative;}
.location-list .location-content{position: relative;display: flex;align-items: center;}
.location-list .location-title{font-size: 18px;font-weight: 500;line-height: 1;display: flex;align-items: center;}
.location-list .location-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.location-list .location-categories span+span:before{content:','; padding-right: 4px;}
.location-list .location-info{display: flex;align-items: center;flex-wrap: wrap;}
.location-list .location-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.location-list .location-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.location-list .location-info .info-data{color: var(--shades08);}


/*----- JOb Type List -----*/
.jobtype-list{position: relative; margin: 0 0 40px;}
.jobtype-list .jobtype-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.jobtype-list .jobtype-item+.jobtype-item{margin: 16px 0 0;}
.jobtype-list .jobtype-item-row{display: flex;justify-content: space-between; align-items: flex-start;}
.jobtype-list .jobtype-content .jobtype-image{width: 50px;height: 50px;padding: 5px;border: 1px solid var(--bs-gray-300);border-radius: 5px;display: flex;align-items: center;justify-content: center;}
.jobtype-list .jobtype-content .jobtype-image img{width: 100%;height: 100%;object-fit: contain;}
.jobtype-list .jobtype-content .jobtype-details{}
.jobtype-list .jobtype-content .jobtype-details .jobtype-descriptions{display: flex;flex-direction: column;margin-top: 12px;}
.jobtype-list .jobtype-content .jobtype-details span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.jobtype-list .jobtype-content .jobtype-details .jobtype-descriptions .des{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades08);}
.jobtype-list .jobtype-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.jobtype-list .jobtype-type{position: relative;}
.jobtype-list .jobtype-options{display: flex;align-items: center; gap:10px}
.jobtype-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.jobtype-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.jobtype-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.jobtype-list .status.published:before,
.jobtype-list .status.published:after{background-color: var(--colorSuccess);}
.jobtype-list .status.draft:before,
.jobtype-list .status.draft:after{background-color: var(--colorWaning);}
.jobtype-list .jobtype-action{position: relative;}
.jobtype-list .jobtype-content{position: relative;display: flex;align-items: center;}
.jobtype-list .jobtype-title{font-size: 18px;font-weight: 500;line-height: 1;display: flex;align-items: center;}
.jobtype-list .jobtype-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.jobtype-list .jobtype-categories span+span:before{content:','; padding-right: 4px;}
.jobtype-list .jobtype-info{display: flex;align-items: center;flex-wrap: wrap;}
.jobtype-list .jobtype-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.jobtype-list .jobtype-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.jobtype-list .jobtype-info .info-data{color: var(--shades08);}


/*----- Jobs List -----*/
.jobs-list{position: relative; margin: 0 0 40px;}
.jobs-list .jobs-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.jobs-list .jobs-item+.jobs-item{margin: 16px 0 0;}
.jobs-list .jobs-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.jobs-list .jobs-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.jobs-list .jobs-type{position: relative;}
.jobs-list .type{display: flex;align-items: center;gap: 6px;}
.jobs-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.jobs-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.jobs-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.jobs-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.jobs-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.jobs-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.jobs-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.jobs-list .jobs-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.jobs-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.jobs-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.jobs-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.jobs-list .status.published:before,
.jobs-list .status.published:after{background-color: var(--colorSuccess);}
.jobs-list .status.draft:before,
.jobs-list .status.draft:after{background-color: var(--colorWaning);}
.jobs-list .jobs-action{position: relative;}
.jobs-list .jobs-content{position: relative; padding-right: 120px;}
.jobs-list .jobs-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.jobs-list .jobs-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.jobs-list .jobs-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.jobs-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.jobs-list .jobs-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.jobs-list .jobs-categories span+span:before{content:','; padding-right: 4px;}
.jobs-list .jobs-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.jobs-list .jobs-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.jobs-list .jobs-metas .meta-item.full{width:100%}
.jobs-list .jobs-metas .meta-icon{font-size: 26px;color: var(--color02);}
.jobs-list .jobs-metas .meta-content{position: relative;line-height: 1.2;}
.jobs-list .jobs-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.jobs-list .jobs-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.jobs-list .jobs-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.jobs-list .jobs-stock{position: relative;}
.jobs-list .jobs-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.jobs-list .jobs-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.jobs-list .jobs-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.jobs-list .jobs-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.jobs-list .jobs-info{display: flex;align-items: center;flex-wrap: wrap;}
.jobs-list .jobs-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.jobs-list .jobs-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.jobs-list .jobs-info .info-data{color: var(--shades08);}



/*----- Points List -----*/
.points-list{position: relative; margin: 0 0 40px;}
.points-list .points-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.points-list .points-item+.points-item{margin: 16px 0 0;}
.points-list .points-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.points-list .points-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.points-list .points-type{position: relative;}
.points-list .type{display: flex;align-items: center;gap: 6px;}
.points-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.points-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.points-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.points-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.points-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.points-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.points-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.points-list .points-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.points-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.points-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.points-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.points-list .status.published:before,
.points-list .status.published:after{background-color: var(--colorSuccess);}
.points-list .status.draft:before,
.points-list .status.draft:after{background-color: var(--colorWaning);}
.points-list .points-action{position: relative;}
.points-list .points-content{position: relative; padding-right: 120px;}
.points-list .points-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.points-list .points-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.points-list .points-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.points-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.points-list .points-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.points-list .points-categories span+span:before{content:','; padding-right: 4px;}
.points-list .points-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.points-list .points-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.points-list .points-metas .meta-item.full{width:100%}
.points-list .points-metas .meta-icon{font-size: 26px;color: var(--color02);}
.points-list .points-metas .meta-content{position: relative;line-height: 1.2;}
.points-list .points-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.points-list .points-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.points-list .points-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.points-list .points-stock{position: relative;}
.points-list .points-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.points-list .points-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.points-list .points-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.points-list .points-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.points-list .points-info{display: flex;align-items: center;flex-wrap: wrap;}
.points-list .points-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.points-list .points-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.points-list .points-info .info-data{color: var(--shades08);}


/*----- Steps List -----*/
.steps-list{position: relative; margin: 0 0 40px;}
.steps-list .steps-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.steps-list .steps-item+.steps-item{margin: 16px 0 0;}
.steps-list .steps-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.steps-list .steps-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.steps-list .steps-type{position: relative;}
.steps-list .type{display: flex;align-items: center;gap: 6px;}
.steps-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.steps-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.steps-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.steps-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.steps-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.steps-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.steps-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.steps-list .steps-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.steps-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.steps-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.steps-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.steps-list .status.published:before,
.steps-list .status.published:after{background-color: var(--colorSuccess);}
.steps-list .status.draft:before,
.steps-list .status.draft:after{background-color: var(--colorWaning);}
.steps-list .steps-action{position: relative;}
.steps-list .steps-content{position: relative; padding-right: 120px;}
.steps-list .steps-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.steps-list .steps-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.steps-list .steps-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.steps-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.steps-list .steps-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.steps-list .steps-categories span+span:before{content:','; padding-right: 4px;}
.steps-list .steps-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.steps-list .steps-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.steps-list .steps-metas .meta-item.full{width:100%}
.steps-list .steps-metas .meta-icon{font-size: 26px;color: var(--color02);}
.steps-list .steps-metas .meta-content{position: relative;line-height: 1.2;}
.steps-list .steps-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.steps-list .steps-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.steps-list .steps-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.steps-list .steps-stock{position: relative;}
.steps-list .steps-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.steps-list .steps-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.steps-list .steps-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.steps-list .steps-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.steps-list .steps-info{display: flex;align-items: center;flex-wrap: wrap;}
.steps-list .steps-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.steps-list .steps-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.steps-list .steps-info .info-data{color: var(--shades08);}


/*----- Services List -----*/
.services-list{position: relative; margin: 0 0 40px;}
.services-list .services-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.services-list .services-item+.services-item{margin: 16px 0 0;}
.services-list .services-item-row{display: flex;justify-content: space-between; align-items: flex-start;}
.services-list .services-content .services-image{width: 70px;height: 70px;padding: 5px;border: 1px solid var(--bs-gray-300);border-radius: 5px;display: flex;align-items: center;justify-content: center;}
.services-list .services-content .services-image img{width: 100%;height: 100%;object-fit: cover;}
.services-list .services-content .services-details{width: 100%;max-width: calc(100% - 90px);margin-left: 20px;}
.services-list .services-content .services-details span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.services-list .services-content .services-details .services-descriptions{display: flex;flex-direction: column;margin-top: 12px;}
.services-list .services-content .services-details .services-descriptions span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.services-list .services-content .services-details .services-descriptions .des{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades08);}
.services-list .services-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.services-list .services-type{position: relative;}
.services-list .services-options{display: flex;align-items: center; gap:10px}
.services-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.services-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.services-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.services-list .status.published:before,
.services-list .status.published:after{background-color: var(--colorSuccess);}
.services-list .status.draft:before,
.services-list .status.draft:after{background-color: var(--colorWaning);}
.services-list .services-action{position: relative;}
.services-list .services-content{position: relative;display: flex;}
.services-list .services-title{font-size: 18px;font-weight: 500;line-height: 1;display: flex;align-items: center;}
.services-list .services-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.services-list .services-categories span+span:before{content:','; padding-right: 4px;}
.services-list .services-info{display: flex;align-items: center;flex-wrap: wrap;}
.services-list .services-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.services-list .services-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.services-list .services-info .info-data{color: var(--shades08);}


/*----- Steps List -----*/
.steps-list{position: relative; margin: 0 0 40px;}
.steps-list .steps-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.steps-list .steps-item+.steps-item{margin: 16px 0 0;}
.steps-list .steps-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.steps-list .steps-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.steps-list .steps-type{position: relative;}
.steps-list .type{display: flex;align-items: center;gap: 6px;}
.steps-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.steps-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.steps-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.steps-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.steps-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.steps-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.steps-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.steps-list .steps-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.steps-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.steps-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.steps-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.steps-list .status.published:before,
.steps-list .status.published:after{background-color: var(--colorSuccess);}
.steps-list .status.draft:before,
.steps-list .status.draft:after{background-color: var(--colorWaning);}
.steps-list .steps-action{position: relative;}
.steps-list .steps-content{position: relative; padding-right: 120px;}
.steps-list .steps-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.steps-list .steps-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.steps-list .steps-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.steps-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.steps-list .steps-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.steps-list .steps-categories span+span:before{content:','; padding-right: 4px;}
.steps-list .steps-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.steps-list .steps-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.steps-list .steps-metas .meta-item.full{width:100%}
.steps-list .steps-metas .meta-icon{font-size: 26px;color: var(--color02);}
.steps-list .steps-metas .meta-content{position: relative;line-height: 1.2;}
.steps-list .steps-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.steps-list .steps-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.steps-list .steps-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.steps-list .steps-stock{position: relative;}
.steps-list .steps-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.steps-list .steps-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.steps-list .steps-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.steps-list .steps-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.steps-list .steps-info{display: flex;align-items: center;flex-wrap: wrap;}
.steps-list .steps-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.steps-list .steps-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.steps-list .steps-info .info-data{color: var(--shades08);}


/*----- Benefits List -----*/
.benefits-list{position: relative; margin: 0 0 40px;}
.benefits-list .benefits-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.benefits-list .benefits-item+.benefits-item{margin: 16px 0 0;}
.benefits-list .benefits-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.benefits-list .benefits-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.benefits-list .benefits-type{position: relative;}
.benefits-list .type{display: flex;align-items: center;gap: 6px;}
.benefits-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.benefits-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.benefits-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.benefits-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.benefits-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.benefits-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.benefits-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.benefits-list .benefits-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.benefits-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.benefits-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.benefits-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.benefits-list .status.published:before,
.benefits-list .status.published:after{background-color: var(--colorSuccess);}
.benefits-list .status.draft:before,
.benefits-list .status.draft:after{background-color: var(--colorWaning);}
.benefits-list .benefits-action{position: relative;}
.benefits-list .benefits-content{position: relative; padding-right: 120px;}
.benefits-list .benefits-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.benefits-list .benefits-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.benefits-list .benefits-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.benefits-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.benefits-list .benefits-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.benefits-list .benefits-categories span+span:before{content:','; padding-right: 4px;}
.benefits-list .benefits-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.benefits-list .benefits-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.benefits-list .benefits-metas .meta-item.full{width:100%}
.benefits-list .benefits-metas .meta-icon{font-size: 26px;color: var(--color02);}
.benefits-list .benefits-metas .meta-content{position: relative;line-height: 1.2;}
.benefits-list .benefits-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.benefits-list .benefits-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.benefits-list .benefits-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.benefits-list .benefits-stock{position: relative;}
.benefits-list .benefits-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.benefits-list .benefits-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.benefits-list .benefits-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.benefits-list .benefits-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.benefits-list .benefits-info{display: flex;align-items: center;flex-wrap: wrap;}
.benefits-list .benefits-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.benefits-list .benefits-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.benefits-list .benefits-info .info-data{color: var(--shades08);}


/*----- Fun Facts List -----*/
.funfacts-list{position: relative; margin: 0 0 40px;}
.funfacts-list .funfacts-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.funfacts-list .funfacts-item+.funfacts-item{margin: 16px 0 0;}
.funfacts-list .funfacts-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.funfacts-list .funfacts-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.funfacts-list .funfacts-type{position: relative;}
.funfacts-list .type{display: flex;align-items: center;gap: 6px;}
.funfacts-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.funfacts-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.funfacts-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.funfacts-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.funfacts-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.funfacts-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.funfacts-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.funfacts-list .funfacts-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.funfacts-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.funfacts-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.funfacts-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.funfacts-list .status.published:before,
.funfacts-list .status.published:after{background-color: var(--colorSuccess);}
.funfacts-list .status.draft:before,
.funfacts-list .status.draft:after{background-color: var(--colorWaning);}
.funfacts-list .funfacts-action{position: relative;}
.funfacts-list .funfacts-content{position: relative; padding-right: 120px;}
.funfacts-list .funfacts-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.funfacts-list .funfacts-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.funfacts-list .funfacts-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.funfacts-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.funfacts-list .funfacts-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.funfacts-list .funfacts-categories span+span:before{content:','; padding-right: 4px;}
.funfacts-list .funfacts-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.funfacts-list .funfacts-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.funfacts-list .funfacts-metas .meta-item.full{width:100%}
.funfacts-list .funfacts-metas .meta-icon{font-size: 26px;color: var(--color02);}
.funfacts-list .funfacts-metas .meta-content{position: relative;line-height: 1.2;}
.funfacts-list .funfacts-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.funfacts-list .funfacts-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.funfacts-list .funfacts-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.funfacts-list .funfacts-stock{position: relative;}
.funfacts-list .funfacts-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.funfacts-list .funfacts-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.funfacts-list .funfacts-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.funfacts-list .funfacts-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.funfacts-list .funfacts-info{display: flex;align-items: center;flex-wrap: wrap;}
.funfacts-list .funfacts-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.funfacts-list .funfacts-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.funfacts-list .funfacts-info .info-data{color: var(--shades08);}


/*----- Team List -----*/
.team-list{position: relative; margin: 0 0 40px;}
.team-list .team-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.team-list .team-item+.team-item{margin: 16px 0 0;}
.team-list .team-item-row{display: flex;justify-content: space-between;align-items: center;position: relative;column-gap: 20px;row-gap: 10px;}
.team-list .team-content .team-image{width: 100px;height: 100px;padding: 5px;border: 1px solid var(--bs-gray-300);border-radius: 5px;display: flex;align-items: center;justify-content: center;}
.team-list .team-content .team-image img{width: 100%;height: 100%;object-fit: cover;}
.team-list .team-content .team-details{width: 100%;max-width: calc(100% - 120px);margin-left: 20px;}
.team-list .team-content .team-details span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.team-list .team-content .team-details .team-descriptions{display: flex;flex-direction: column;margin-top: 12px;}
.team-list .team-content .team-details .team-descriptions span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.team-list .team-content .team-details .team-descriptions .des{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades08);}
.team-list .team-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.team-list .team-type{position: relative;}
.team-list .team-options{display: flex;align-items: center;gap: 10px;margin-left: auto;position: absolute;top: -5px;right: 0;z-index: 1;}
.team-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.team-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.team-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.team-list .team-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px;row-gap: 10px;}
.team-list .team-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.team-list .team-metas .meta-item.full{width:100%}
.team-list .team-metas .meta-icon{font-size: 26px;color: var(--color02);}
.team-list .team-metas .meta-content{position: relative;line-height: 1.2;}
.team-list .team-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.team-list .team-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.team-list .team-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.team-list .status.published:before,
.team-list .status.published:after{background-color: var(--colorSuccess);}
.team-list .status.draft:before,
.team-list .status.draft:after{background-color: var(--colorWaning);}
.team-list .team-action{position: relative;}
.team-list .team-content{position: relative;display: flex;}
.team-list .team-title{font-size: 18px;font-weight: 500;line-height: 1;display: flex;align-items: center;}
.team-list .team-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.team-list .team-categories span+span:before{content:','; padding-right: 4px;}
.team-list .team-info{display: flex;align-items: center;flex-wrap: wrap;}
.team-list .team-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.team-list .team-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.team-list .team-info .info-data{color: var(--shades08);}


/*----- Mission List -----*/
.mission-list{position: relative; margin: 0 0 40px;}
.mission-list .mission-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.mission-list .mission-item+.mission-item{margin: 16px 0 0;}
.mission-list .mission-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.mission-list .mission-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.mission-list .mission-type{position: relative;}
.mission-list .type{display: flex;align-items: center;gap: 6px;}
.mission-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.mission-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.mission-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.mission-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.mission-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.mission-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.mission-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.mission-list .mission-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.mission-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.mission-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.mission-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.mission-list .status.published:before,
.mission-list .status.published:after{background-color: var(--colorSuccess);}
.mission-list .status.draft:before,
.mission-list .status.draft:after{background-color: var(--colorWaning);}
.mission-list .mission-action{position: relative;}
.mission-list .mission-content{position: relative; padding-right: 120px;}
.mission-list .mission-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.mission-list .mission-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.mission-list .mission-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.mission-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.mission-list .mission-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.mission-list .mission-categories span+span:before{content:','; padding-right: 4px;}
.mission-list .mission-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.mission-list .mission-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.mission-list .mission-metas .meta-item.full{width:100%}
.mission-list .mission-metas .meta-icon{font-size: 26px;color: var(--color02);}
.mission-list .mission-metas .meta-content{position: relative;line-height: 1.2;}
.mission-list .mission-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.mission-list .mission-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.mission-list .mission-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.mission-list .mission-stock{position: relative;}
.mission-list .mission-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.mission-list .mission-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.mission-list .mission-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.mission-list .mission-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.mission-list .mission-info{display: flex;align-items: center;flex-wrap: wrap;}
.mission-list .mission-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.mission-list .mission-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.mission-list .mission-info .info-data{color: var(--shades08);}


/*----- Vision List -----*/
.vision-list{position: relative; margin: 0 0 40px;}
.vision-list .vision-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.vision-list .vision-item+.vision-item{margin: 16px 0 0;}
.vision-list .vision-item-row{display: flex;justify-content: space-between; align-items: flex-start;}
.vision-list .vision-content .vision-image{width: 70px;height: 70px;padding: 5px;border: 1px solid var(--bs-gray-300);border-radius: 5px;display: flex;align-items: center;justify-content: center;}
.vision-list .vision-content .vision-image img{width: 100%;height: 100%;object-fit: cover;}
.vision-list .vision-content .vision-details{width: 100%;max-width: calc(100% - 90px);margin-left: 20px;}
.vision-list .vision-content .vision-details span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.vision-list .vision-content .vision-details .vision-descriptions{display: flex;flex-direction: column;margin-top: 12px;}
.vision-list .vision-content .vision-details .vision-descriptions span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.vision-list .vision-content .vision-details .vision-descriptions .des{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades08);}
.vision-list .vision-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.vision-list .vision-type{position: relative;}
.vision-list .vision-options{display: flex;align-items: center; gap:10px}
.vision-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.vision-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.vision-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.vision-list .status.published:before,
.vision-list .status.published:after{background-color: var(--colorSuccess);}
.vision-list .status.draft:before,
.vision-list .status.draft:after{background-color: var(--colorWaning);}
.vision-list .vision-action{position: relative;}
.vision-list .vision-content{position: relative;display: flex;}
.vision-list .vision-title{font-size: 18px;font-weight: 500;line-height: 1;display: flex;align-items: center;}
.vision-list .vision-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.vision-list .vision-categories span+span:before{content:','; padding-right: 4px;}
.vision-list .vision-info{display: flex;align-items: center;flex-wrap: wrap;}
.vision-list .vision-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.vision-list .vision-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.vision-list .vision-info .info-data{color: var(--shades08);}


/*----- Highlights List -----*/
.highlights-list{position: relative; margin: 0 0 40px;}
.highlights-list .highlights-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.highlights-list .highlights-item+.highlights-item{margin: 16px 0 0;}
.highlights-list .highlights-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.highlights-list .highlights-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.highlights-list .highlights-type{position: relative;}
.highlights-list .type{display: flex;align-items: center;gap: 6px;}
.highlights-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.highlights-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.highlights-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.highlights-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.highlights-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.highlights-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.highlights-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.highlights-list .highlights-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.highlights-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.highlights-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.highlights-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.highlights-list .status.published:before,
.highlights-list .status.published:after{background-color: var(--colorSuccess);}
.highlights-list .status.draft:before,
.highlights-list .status.draft:after{background-color: var(--colorWaning);}
.highlights-list .highlights-action{position: relative;}
.highlights-list .highlights-content{position: relative; padding-right: 120px;}
.highlights-list .highlights-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.highlights-list .highlights-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.highlights-list .highlights-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.highlights-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.highlights-list .highlights-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.highlights-list .highlights-categories span+span:before{content:','; padding-right: 4px;}
.highlights-list .highlights-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.highlights-list .highlights-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.highlights-list .highlights-metas .meta-item.full{width:100%}
.highlights-list .highlights-metas .meta-icon{font-size: 26px;color: var(--color02);}
.highlights-list .highlights-metas .meta-content{position: relative;line-height: 1.2;}
.highlights-list .highlights-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.highlights-list .highlights-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.highlights-list .highlights-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.highlights-list .highlights-stock{position: relative;}
.highlights-list .highlights-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.highlights-list .highlights-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.highlights-list .highlights-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.highlights-list .highlights-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.highlights-list .highlights-info{display: flex;align-items: center;flex-wrap: wrap;}
.highlights-list .highlights-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.highlights-list .highlights-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.highlights-list .highlights-info .info-data{color: var(--shades08);}


/*----- Future Team List -----*/
.future-list{position: relative; margin: 0 0 40px;}
.future-list .future-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.future-list .future-item+.future-item{margin: 16px 0 0;}
.future-list .future-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.future-list .future-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.future-list .future-type{position: relative;}
.future-list .type{display: flex;align-items: center;gap: 6px;}
.future-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.future-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.future-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.future-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.future-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.future-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.future-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.future-list .future-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.future-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.future-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.future-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.future-list .status.published:before,
.future-list .status.published:after{background-color: var(--colorSuccess);}
.future-list .status.draft:before,
.future-list .status.draft:after{background-color: var(--colorWaning);}
.future-list .future-action{position: relative;}
.future-list .future-content{position: relative; padding-right: 120px;}
.future-list .future-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.future-list .future-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.future-list .future-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.future-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.future-list .future-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.future-list .future-categories span+span:before{content:','; padding-right: 4px;}
.future-list .future-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.future-list .future-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.future-list .future-metas .meta-item.full{width:100%}
.future-list .future-metas .meta-icon{font-size: 26px;color: var(--color02);}
.future-list .future-metas .meta-content{position: relative;line-height: 1.2;}
.future-list .future-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.future-list .future-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.future-list .future-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.future-list .future-stock{position: relative;}
.future-list .future-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.future-list .future-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.future-list .future-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.future-list .future-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.future-list .future-info{display: flex;align-items: center;flex-wrap: wrap;}
.future-list .future-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.future-list .future-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.future-list .future-info .info-data{color: var(--shades08);}


/*----- Opening Hours List -----*/
.openinghours-list{position: relative; margin: 0 0 40px;}
.openinghours-list .openinghours-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.openinghours-list .openinghours-item+.openinghours-item{margin: 16px 0 0;}
.openinghours-list .openinghours-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.openinghours-list .openinghours-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.openinghours-list .openinghours-type{position: relative;}
.openinghours-list .type{display: flex;align-items: center;gap: 6px;}
.openinghours-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.openinghours-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.openinghours-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.openinghours-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.openinghours-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.openinghours-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.openinghours-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.openinghours-list .openinghours-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.openinghours-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.openinghours-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.openinghours-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.openinghours-list .status.published:before,
.openinghours-list .status.published:after{background-color: var(--colorSuccess);}
.openinghours-list .status.draft:before,
.openinghours-list .status.draft:after{background-color: var(--colorWaning);}
.openinghours-list .openinghours-action{position: relative;}
.openinghours-list .openinghours-content{position: relative; padding-right: 120px;}
.openinghours-list .openinghours-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.openinghours-list .openinghours-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;text-transform: capitalize;}
.openinghours-list .openinghours-text{font-size: 13px;line-height: 1.2;color: var(--shades07);}
.openinghours-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.openinghours-list .openinghours-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.openinghours-list .openinghours-categories span+span:before{content:','; padding-right: 4px;}
.openinghours-list .openinghours-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.openinghours-list .openinghours-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.openinghours-list .openinghours-metas .meta-item.full{width:100%}
.openinghours-list .openinghours-metas .meta-icon{font-size: 26px;color: var(--color02);}
.openinghours-list .openinghours-metas .meta-content{position: relative;line-height: 1.2;}
.openinghours-list .openinghours-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.openinghours-list .openinghours-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.openinghours-list .openinghours-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.openinghours-list .openinghours-stock{position: relative;}
.openinghours-list .openinghours-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.openinghours-list .openinghours-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.openinghours-list .openinghours-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.openinghours-list .openinghours-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.openinghours-list .openinghours-info{display: flex;align-items: center;flex-wrap: wrap;}
.openinghours-list .openinghours-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.openinghours-list .openinghours-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.openinghours-list .openinghours-info .info-data{color: var(--shades08);}


/*----- Pages List -----*/
.pages-list{position: relative; margin: 0 0 40px;}
.pages-list .pages-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.pages-list .pages-item+.pages-item{margin: 16px 0 0;}
.pages-list .pages-item-row{display: flex;justify-content: space-between; align-items: center;position: relative;column-gap: 20px; row-gap: 10px;}
.pages-list .pages-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.pages-list .pages-type{position: relative;}
.pages-list .type{display: flex;align-items: center;gap: 6px;}
.pages-list .type .type-icon{width: 16px;height: 16px;border: 1px solid var(--shades05);border-radius: 4px;position: relative;}
.pages-list .type .type-icon:before{content:''; position: absolute; width: 8px; height: 8px; background-color: var(--shades05); border-radius: 50%; top:50%; left: 50%; transform: translate(-50%,-50%);}
.pages-list .type .type-title{font-size: 13px;text-transform: uppercase;font-weight: 500;color: var(--shades06);line-height: 1;}
.pages-list .type.veg .type-icon{border-color:var(--colorSuccess);}
.pages-list .type.veg .type-icon:before{background-color:var(--colorSuccess);}
.pages-list .type.non-veg .type-icon{border-color:var(--colorDanger);}
.pages-list .type.non-veg .type-icon:before{background-color:var(--colorDanger);}
.pages-list .pages-options{display: flex;align-items: center; gap:10px; margin-left: auto;position: absolute;top: -5px;right: 0; z-index: 1;}
.pages-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.pages-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.pages-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.pages-list .status.published:before,
.pages-list .status.published:after{background-color: var(--colorSuccess);}
.pages-list .status.draft:before,
.pages-list .status.draft:after{background-color: var(--colorWaning);}
.pages-list .pages-action{position: relative;}
.pages-list .pages-content{position: relative; padding-right: 120px;}
.pages-list .pages-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.pages-list .pages-title{font-size: 18px;font-weight: 600;line-height: 1;display: flex;align-items: center;}
.pages-list .pages-text{font-size: 14px;line-height: 1.2;color: var(--shades07);font-weight: 500;}
.pages-list .popular{display: inline-flex;align-items: center;justify-content: center;background-color: var(--colorWaning);color: var(--shades01);font-size: 10px;font-weight: 400;padding: 4px 8px;border-radius: 30px;line-height: 1;margin: 0 0 0 10px;}
.pages-list .pages-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.pages-list .pages-categories span+span:before{content:','; padding-right: 4px;}
.pages-list .pages-metas{display: flex;flex-wrap: wrap;margin: 16px 0 0;column-gap: 40px; row-gap:10px;}
.pages-list .pages-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.pages-list .pages-metas .meta-item.full{width:100%}
.pages-list .pages-metas .meta-icon{font-size: 26px;color: var(--color02);}
.pages-list .pages-metas .meta-content{position: relative;line-height: 1.2;}
.pages-list .pages-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.pages-list .pages-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.pages-list .pages-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.pages-list .pages-stock{position: relative;}
.pages-list .pages-stock .radio-group .radio-item .radio-label{background-color: var(--shades03);}
.pages-list .pages-price{display: flex;align-items: center;gap: 10px; margin-left: auto;position: absolute;bottom: 5px;right: 0;}
.pages-list .pages-price .price{font-size: 24px;font-weight: 600;color: var(--color02);}
.pages-list .pages-price .price.strike{color: var(--shades05);font-size: 18px;text-decoration: line-through;}
.pages-list .pages-info{display: flex;align-items: center;flex-wrap: wrap;}
.pages-list .pages-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.pages-list .pages-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.pages-list .pages-info .info-data{color: var(--shades08);}


/*----- Banner List -----*/
.banner-list{position: relative; margin: 0 0 40px;}
.banner-list .banner-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.banner-list .banner-item+.banner-item{margin: 16px 0 0;}
.banner-list .banner-item-row{display: flex;justify-content: space-between; align-items: flex-start;}
.banner-list .banner-content .banner-image{width: 70px;height: 70px;padding: 5px;border: 1px solid var(--bs-gray-300);border-radius: 5px;display: flex;align-items: center;justify-content: center;}
.banner-list .banner-content .banner-image img{width: 100%;height: 100%;object-fit: cover;}
.banner-list .banner-content .banner-details{width: 100%;max-width: calc(100% - 90px);margin-left: 20px;}
.banner-list .banner-content .banner-details span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.banner-list .banner-content .banner-details .banner-descriptions{display: flex;flex-direction: column;margin-top: 12px;}
.banner-list .banner-content .banner-details .banner-descriptions span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.banner-list .banner-content .banner-details .banner-descriptions .des{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades08);}
.banner-list .banner-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.banner-list .banner-type{position: relative;}
.banner-list .banner-options{display: flex;align-items: center; gap:10px}
.banner-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.banner-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.banner-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.banner-list .status.published:before,
.banner-list .status.published:after{background-color: var(--colorSuccess);}
.banner-list .status.draft:before,
.banner-list .status.draft:after{background-color: var(--colorWaning);}
.banner-list .banner-action{position: relative;}
.banner-list .banner-content{position: relative;display: flex;}
.banner-list .banner-title{font-size: 18px;font-weight: 500;line-height: 1;display: flex;align-items: center;}
.banner-list .banner-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.banner-list .banner-categories span+span:before{content:','; padding-right: 4px;}
.banner-list .banner-info{display: flex;align-items: center;flex-wrap: wrap;}
.banner-list .banner-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.banner-list .banner-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.banner-list .banner-info .info-data{color: var(--shades08);}

/*----- Live Order List -----*/
.live-order-part{display: flex;margin: 0 -10px;}
.live-order-part .live-order-list{background: var(--shades01);border: 1px solid var(--shades04);width: 100%;max-width: calc(25% - 20px);margin: 10px;padding: 16px;
  border-radius: 6px;}
.live-order-part .live-order-list .live-order-row{}
.live-order-part .live-order-list .live-order-row .live-list{} 
.live-order-part .live-order-list .live-order-row .live-list .heading-part{display: flex;align-items: center;justify-content: center;background: var(--shades03);padding: 10px 0;border-radius: 6px;} 
.live-order-part .live-order-list .live-order-row .live-list .heading-part .icon{font-size: 16px;margin-right: 10px;color: var(--color02);} 
.live-order-part .live-order-list .live-order-row .live-list .heading-part .text{font-size: 16px;font-weight: 600;} 
.live-order-part .live-order-list .live-order-row .live-list .live-item{margin-top: 14px;} 
.live-order-list .live-order-row .live-list .live-item .data-order{display: flex;justify-content: space-between;}
.live-order-list .live-order-row .live-list .live-item .data-order .order-time{font-size: 12px;font-weight: 500;color: var(--shades08);}
.live-order-list .live-order-row .live-list .live-item .data-order{}
.live-order-part .live-order-list .live-order-row .live-list .live-item+ .live-item{border-top: 1px solid var(--shades04);padding-top: 14px;}
.live-order-part .live-order-list .live-order-row .live-list .live-item .order-id{font-size: 14px;font-weight: 600;} 
.live-order-part .live-order-list .live-order-row .live-list .live-item .order-info{display: flex;justify-content: space-between;align-items: flex-end;} 
.live-order-part .live-order-list .live-order-row .live-list .live-item .order-info .data-info{width: 100%;max-width: calc(100% - 50px);margin-right: 10px;} 
.live-order-part .live-order-list .live-order-row .live-list .live-item .order-info .data-info .info{font-size: 14px;font-weight: 400;} 
.live-order-part .live-order-list .live-order-row .live-list .live-item .order-info .data-icon{width: 40px;font-size: 20px;display: flex;justify-content: flex-end;color: var(--color02);} 
.live-order-part .live-order-list .live-order-row .live-list .live-item .order-action{display: flex;justify-content: flex-end;margin-top: 10px;}
.live-order-part .live-order-list .live-order-row .live-list .live-item .order-action .btn-action{background: var(--color02);color: var(--shades01);font-size: 12px;padding: 5px 10px;border-radius: 5px;height: 30px;}
.live-order-part .live-order-list .live-order-row .live-list .live-item .order-action .btn-action .button-label{font-size: 12px;}
.live-order-part .live-order-list .live-order-row .live-list .live-item .order-action .btn-action+ .btn-action{margin-left: 10px;}
.live-order-part .live-order-list .live-order-row .live-list .live-item .order-action .btn-action.alt{background: none;border: 1px solid var(--shades05);color: var(--shades05);}

/*----- Live Order Reject List -----*/
.order-info{font-size: 12px;font-weight: 400;text-transform: capitalize;}
.order-info span{font-size: 14px;font-weight: 600;text-transform: capitalize;margin-left: 5px;}

/*----- Delivery Settings List -----*/
.delivery-settings-list{}
.delivery-settings-list .table-inner{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.delivery-settings-list .table-inner .table-data{padding: 10px 0 0;}
.delivery-settings-list .table-inner .delivery-title{font-size: 16px;font-weight: 600;color: var(--color02);border-bottom: 1px solid var(--shades04);padding-bottom: 10px;}
.delivery-settings-list .table-inner .table-data .item-data-row{display: flex;align-items: center;}
.delivery-settings-list .table-inner .table-data .item-data-row.header-row{padding-bottom: 10px;border-bottom: 1px solid var(--shades04);}
.delivery-settings-list .table-inner .table-body-part{margin-top: 15px;}
.delivery-settings-list .table-inner .table-body-part .item-data-row{padding-bottom: 8px;}
.delivery-settings-list .table-inner .table-body-part .item-data-row+ .item-data-row{padding-top: 8px;margin-bottom: 8px;}
.delivery-settings-list .table-inner .table-body-part .item-data-row .postal-code{width: 33.33%;font-size: 14px;color: var(--shades06);font-weight: 400;}
.delivery-settings-list .table-inner .table-body-part .item-data-row .min-order-value{width: 33.33%;text-align: right;font-size: 14px;color: var(--shades06);font-weight: 400;}
.delivery-settings-list .table-inner .table-body-part .item-data-row .delivery-costs{width: 33.33%;text-align: right;font-size: 14px;color: var(--shades06);font-weight: 400;}

.delivery-settings-list .table-inner .table-data .item-data-row.header-row .postal-code{width: 33.33%;font-size: 14px;
  font-weight: 600;}
.delivery-settings-list .table-inner .table-data .item-data-row.header-row .min-order-value{width: 33.33%;text-align: right;    font-size: 14px;font-weight: 600;text-transform: capitalize;}
.delivery-settings-list .table-inner .table-data .item-data-row.header-row .delivery-costs{width: 33.33%;text-align: right;    font-size: 14px;font-weight: 600;text-transform: capitalize;}


/*----- Admin List -----*/
.admin-list{position: relative; margin: 0 0 40px;}
.admin-list .admin-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.admin-list .admin-item+.admin-item{margin: 16px 0 0;}
.admin-list .admin-item-row{display: flex;justify-content: space-between;align-items: center;position: relative;column-gap: 20px;row-gap: 10px;}
.admin-list .admin-content .admin-image{width: 80px;height: 80px;padding: 5px;border: 1px solid var(--bs-gray-300);border-radius: 5px;display: flex;align-items: center;justify-content: center;}
.admin-list .admin-content .admin-image img{width: 100%;height: 100%;object-fit: cover;}
.admin-list .admin-content .admin-details{width: 100%;max-width: calc(100% - 100px);margin-left: 20px;}
.admin-list .admin-content .admin-details span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.admin-list .admin-content .admin-details .admin-descriptions{display: flex;flex-direction: column;margin-top: 12px;}
.admin-list .admin-content .admin-details .admin-descriptions span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.admin-list .admin-content .admin-details .admin-descriptions .des{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades08);}
.admin-list .admin-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.admin-list .admin-type{position: relative;}
.admin-list .admin-options{display: flex;align-items: center;gap: 10px;margin-left: auto;position: absolute;top: -5px;right: 0;z-index: 1;}
.admin-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.admin-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.admin-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.admin-list .admin-metas{display: flex;flex-wrap: wrap;margin: 10px 0 0;column-gap: 40px;row-gap: 10px;}
.admin-list .admin-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.admin-list .admin-metas .meta-item.full{width:100%}
.admin-list .admin-metas .meta-icon{font-size: 26px;color: var(--color02);}
.admin-list .admin-metas .meta-content{position: relative;line-height: 1.2;}
.admin-list .admin-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.admin-list .admin-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.admin-list .admin-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.admin-list .status.published:before,
.admin-list .status.published:after{background-color: var(--colorSuccess);}
.admin-list .status.draft:before,
.admin-list .status.draft:after{background-color: var(--colorWaning);}
.admin-list .admin-action{position: relative;}
.admin-list .admin-content{position: relative;display: flex;}
.admin-list .admin-title{font-size: 18px;font-weight: 500;line-height: 1;display: flex;align-items: center;}
.admin-list .admin-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.admin-list .admin-categories span+span:before{content:','; padding-right: 4px;}
.admin-list .admin-info{display: flex;align-items: center;flex-wrap: wrap;}
.admin-list .admin-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.admin-list .admin-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.admin-list .admin-info .info-data{color: var(--shades08);}



/*----- Customer List -----*/
.customer-list{position: relative; margin: 0 0 40px;}
.customer-list .customer-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.customer-list .customer-item+.customer-item{margin: 16px 0 0;}
.customer-list .customer-item-row{display: flex;justify-content: space-between;align-items: center;position: relative;column-gap: 20px;row-gap: 10px;}
.customer-list .customer-content .customer-image{width: 80px;height: 80px;padding: 5px;border: 1px solid var(--bs-gray-300);border-radius: 5px;display: flex;align-items: center;justify-content: center;}
.customer-list .customer-content .customer-image img{width: 100%;height: 100%;object-fit: cover;}
.customer-list .customer-content .customer-details{width: 100%;max-width: calc(100% - 100px);margin-left: 20px;}
.customer-list .customer-content .customer-details span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.customer-list .customer-content .customer-details .customer-descriptions{display: flex;flex-direction: column;margin-top: 12px;}
.customer-list .customer-content .customer-details .customer-descriptions span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.customer-list .customer-content .customer-details .customer-descriptions .des{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades08);}
.customer-list .customer-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.customer-list .customer-type{position: relative;}
.customer-list .customer-options{display: flex;align-items: center;gap: 10px;margin-left: auto;position: absolute;top: -5px;right: 0;z-index: 1;}
.customer-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.customer-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.customer-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.customer-list .customer-metas{display: flex;flex-wrap: wrap;margin: 10px 0 0;column-gap: 40px;row-gap: 10px;}
.customer-list .customer-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.customer-list .customer-metas .meta-item.full{width:100%}
.customer-list .customer-metas .meta-icon{font-size: 26px;color: var(--color02);}
.customer-list .customer-metas .meta-content{position: relative;line-height: 1.2;}
.customer-list .customer-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.customer-list .customer-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.customer-list .customer-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.customer-list .status.published:before,
.customer-list .status.published:after{background-color: var(--colorSuccess);}
.customer-list .status.draft:before,
.customer-list .status.draft:after{background-color: var(--colorWaning);}
.customer-list .customer-action{position: relative;}
.customer-list .customer-content{position: relative;display: flex;}
.customer-list .customer-title{font-size: 18px;font-weight: 500;line-height: 1;display: flex;align-items: center;}
.customer-list .customer-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.customer-list .customer-categories span+span:before{content:','; padding-right: 4px;}
.customer-list .customer-info{display: flex;align-items: center;flex-wrap: wrap;}
.customer-list .customer-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.customer-list .customer-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.customer-list .customer-info .info-data{color: var(--shades08);}

/*----- Driver List -----*/
.driver-list{position: relative; margin: 0 0 40px;}
.driver-list .driver-item{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;}
.driver-list .driver-item+.driver-item{margin: 16px 0 0;}
.driver-list .driver-item-row{display: flex;justify-content: space-between;align-items: center;position: relative;column-gap: 20px;row-gap: 10px;}
.driver-list .driver-content .driver-image{width: 80px;height: 80px;padding: 5px;border: 1px solid var(--bs-gray-300);border-radius: 5px;display: flex;align-items: center;justify-content: center;}
.driver-list .driver-content .driver-image img{width: 100%;height: 100%;object-fit: cover;}
.driver-list .driver-content .driver-details{width: 100%;max-width: calc(100% - 100px);margin-left: 20px;}
.driver-list .driver-content .driver-details span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.driver-list .driver-content .driver-details .driver-descriptions{display: flex;flex-direction: column;margin-top: 12px;}
.driver-list .driver-content .driver-details .driver-descriptions span{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.driver-list .driver-content .driver-details .driver-descriptions .des{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades08);}
.driver-list .driver-item-row.border-t{border-top:1px solid var(--shades04); padding: 10px 0 0; margin: 10px 0 0;}
.driver-list .driver-type{position: relative;}
.driver-list .driver-options{display: flex;align-items: center;gap: 10px;margin-left: auto;position: absolute;top: -5px;right: 0;z-index: 1;}
.driver-list .status{height: 24px;display: flex;align-items: center;justify-content: center;padding:0 8px 0 20px;font-size: 12px;text-transform: capitalize;font-weight: 500;line-height: 1;position: relative;border-radius: 4px;overflow: hidden;color: var(--shades07);z-index: 0;}
.driver-list .status:before{content: '';background-color: var(--shades07);top: 0;left: 0;width: 100%;height: 100%;position: absolute;z-index: -1;opacity: 0.2;}
.driver-list .status:after{content: '';background-color: var(--shades07);top: 50%; transform: translateY(-50%); left: 6px; border-radius: 50%; width: 8px;height: 8px;position: absolute;}
.driver-list .driver-metas{display: flex;flex-wrap: wrap;margin: 10px 0 0;column-gap: 40px;row-gap: 10px;}
.driver-list .driver-metas .meta-item{display: flex; align-items: center;gap: 10px;}
.driver-list .driver-metas .meta-item.full{width:100%}
.driver-list .driver-metas .meta-icon{font-size: 26px;color: var(--color02);}
.driver-list .driver-metas .meta-content{position: relative;line-height: 1.2;}
.driver-list .driver-metas .meta-label{font-size: 11px;text-transform: uppercase;font-weight: 500;color: var(--shades06);}
.driver-list .driver-metas .meta-data{font-size: 14px; font-weight: 500; display: flex; flex-wrap: wrap;}
.driver-list .driver-metas .meta-data span+span:before{content:','; padding-right: 4px;}
.driver-list .status.published:before,
.driver-list .status.published:after{background-color: var(--colorSuccess);}
.driver-list .status.draft:before,
.driver-list .status.draft:after{background-color: var(--colorWaning);}
.driver-list .driver-action{position: relative;}
.driver-list .driver-content{position: relative;display: flex;}
.driver-list .driver-title{font-size: 18px;font-weight: 500;line-height: 1;display: flex;align-items: center;}
.driver-list .driver-categories{font-size: 13px;color: var(--shades06);margin: 4px 0 0;display: flex;flex-wrap: wrap;}
.driver-list .driver-categories span+span:before{content:','; padding-right: 4px;}
.driver-list .driver-info{display: flex;align-items: center;flex-wrap: wrap;}
.driver-list .driver-info .info{display: flex;align-items: center;gap: 5px;font-size: 12px;font-weight: 500;color: var(--shades06);}
.driver-list .driver-info .info+.info{padding-left: 10px; margin-left: 10px; border-left: 1px solid var(--shades05);}
.driver-list .driver-info .info-data{color: var(--shades08);}

/*----- Page Settings -----*/
.page-settings{}
.page-settings .title{font-size: 16px;font-weight: 600;color: var(--color02);border-bottom: 1px solid var(--shades04);padding-bottom: 10px;}


.order-details-content .order-step-progress .order-step .icon.canceled{background-color: var(--colorDanger) !important;}
.order-details-content .order-step-progress .order-step .order-content.canceled > div{color: var(--colorDanger) !important;}

.dashboard-stat{display: flex;}
.dashboard-stat .left-side-stat{width: 100%;max-width: calc(100% - 400px);margin-right: 50px;}
.dashboard-stat .right-side-stat{width: 350px;margin: 20px 0;}
.dashboard-stat .right-side-stat .delivery-stat{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;margin: 10px 0;}
.dashboard-stat .right-side-stat .delivery-item{display: flex;}
.dashboard-stat .right-side-stat .delivery-item+ .delivery-item{margin-top: 15px;border-top: 1px solid var(--shades04);padding-top: 15px;}
.dashboard-stat .right-side-stat .delivery-item .icon{width: 60px;height: 60px;}
.dashboard-stat .right-side-stat .delivery-item .icon img{}
.dashboard-stat .right-side-stat .delivery-item .delivery-content{    width: 100%;max-width: calc(100% - 70px);margin-left: 10px;}
.dashboard-stat .right-side-stat .delivery-item .delivery-content .text{font-size: 16px;font-weight: 600;}
.dashboard-stat .right-side-stat .delivery-item .delivery-content .order-data-row{display: flex;justify-content: space-between;}
.dashboard-stat .right-side-stat .delivery-item .delivery-content .order-data-row .order-row{margin-top: 10px;}
.dashboard-stat .right-side-stat .delivery-item .delivery-content .order-data-row .order-row .sub-text{font-size: 12px;color: var(--bs-gray-700);font-weight: 500;}
.dashboard-stat .right-side-stat .delivery-item .delivery-content .order-data-row .order-row .data{font-weight: 600;font-size: 14px;}
.dashboard-stat .right-side-stat .delivery-item .delivery-content .order-data-row .order-row+ .order-row{text-align: right;}

.stat-overview{position: relative;background-color: var(--shades01);border: 1px solid var(--shades04);border-radius: 8px;padding: 16px;margin: 30px 0;}
.stat-overview .header-part{display: flex;justify-content: space-between;}
.stat-overview .header-part .title{font-size: 18px;font-weight: 600;}
.stat-overview .header-part .data{font-size: 18px;font-weight: 600;}
.stat-overview .statoverview-list{margin-top: 15px;}
.stat-overview .statoverview-list .overview-stat-item{display: flex;justify-content: space-between;}
.stat-overview .statoverview-list .overview-stat-item+ .overview-stat-item{margin-top: 10px;}
.stat-overview .statoverview-list .overview-stat-item .overview-title{display: flex;align-items: center;}
.stat-overview .statoverview-list .overview-stat-item .overview-title .line{width: 3px;height: 15px;margin-right: 5px;}
.stat-overview .statoverview-list .overview-stat-item .overview-title .text{}
.stat-overview .statoverview-list .overview-stat-item .data{}


.statmenutabbed-list{background: var(--shades03); padding: 0;border-bottom: 1px solid var(--shades05);align-items: center;display: flex;height: 40px;list-style: none;margin: 0;position: -webkit-sticky;position: sticky;top: 100px;top: var(--height);width: 100%;z-index: 0;}
.statmenutabbed-list .navigation-items{align-items: center;color: var(--shades05);display: flex;font-size: 14px;font-weight: 500;height: 40px;justify-content: center;letter-spacing: .25px;position: relative;white-space: nowrap;}
.statmenutabbed-list .navigation-items:before{background-color: var(--shades07);border-radius: 20px;bottom: 0;content: "";height: 2px;left: 0;position: absolute;-webkit-transform: scaleX(0);transform: scaleX(0);transition: all .3s ease;width: 100%;}
.statmenutabbed-list li+ li{padding-left: 25px;}
.statmenutabbed-list .navigation-items.active , .statmenutabbed-list .navigation-items:hover{color: var(--shades07);}
.statmenutabbed-list .navigation-items:hover:before , .statmenutabbed-list .navigation-items.active:before{-webkit-transform: scale(1);transform: scale(1);}


.stat-tab-view{   }
.stat-tab-view .chart-dashboard{background-color: var(--shades01);border: 1px solid var(--shades04);margin-top: 30px;border-radius: 8px;padding: 16px;}
.stat-tab-view .chart-dashboard .chart-data-details{display: flex;margin-bottom: 20px;}
.stat-tab-view .chart-dashboard .chart-data-details .data-row{}
.stat-tab-view .chart-dashboard .chart-data-details .data-row+ .data-row{margin-left: 100px;}
.stat-tab-view .chart-dashboard .chart-data-details .data-row .main-data{font-size: 30px;font-weight: 600;line-height: 1.2; display: flex; justify-content: center; align-items: center; gap: 20px;}
.stat-tab-view .chart-dashboard .chart-data-details .data-row .main-data span.danger{font-size: 12px;background: var(--bs-danger);color: var(--shades01);font-weight: 400;padding: 2px 15px;border-radius: 50px;}
.stat-tab-view .chart-dashboard .chart-data-details .data-row .main-data span.ok{font-size: 12px;background: var(--colorSuccess);color: var(--shades01);font-weight: 400;padding: 2px 15px;border-radius: 50px;}
.stat-tab-view .chart-dashboard .chart-data-details .data-row .sub-text{font-size: 14px;color: var(--shades06);}

.chart-dashboard{}
.chart-dashboard .chart-view{border-top: 1px solid var(--shades04);padding-top: 30px;}
.chart-data{display: flex;align-items: center;}
.chart-data .css-b62m3t-container{ position: absolute; right: 450px; }
.chart-data .date{font-size: 14px;color: var(--shades07);font-weight: 400;}
.chart-data .date.previous{color: var(--shades05); margin-right: 450px;}
.chart-data .seperator{width: 50px;height: 2px;background: var(--shades05);margin: 0 20px;}